import { useState } from "react";
import { Tooltip } from "react-tooltip";

import { Tag, Typography } from "design2impact";

import CourseCircularProgress from "../circular-progress";
import CourseProgressDetails from "../progress-details";
import Requirements from "../requirements";
import Skills from "../skills";
import CardCourseStats from "../stats";
import { FaMinus, FaPlus } from "react-icons/fa";

import { Container, Course } from "types/defs";

interface CardCourseExpandibleProps {
	guideTypeId: string
	pic_url: string
	isFinalProject: boolean
	course: Course
	requirements: Container["details"][number]["requirements"];
}

function CardCourseExpandible({
	guideTypeId = "", // is a final project container?
	pic_url,
	isFinalProject = false,
	course,
	requirements = [],
}: CardCourseExpandibleProps) {
	const {
		id,
		title,
		slug,
		duration_guide,
		duration_project,
		max_points_guide,
		project_groups_count,
		description,
		skills,
		progress,
		is_sponsored = false,
		tests_count,
		theories_count,
	} = course;

	const [isCollapsed, setIsCollapsed] = useState(true);
	const guidePath = `/corsi/${slug}`;
	const studentCompletedGuide = (progress?.projects_points && project_groups_count) || (!project_groups_count && progress?.test_passed_points);


	return (
		<div
			className={`rounded-[10px] ${guideTypeId === "d6bf37d8-6cd9-40ae-81ca-ee32f0782be1"
				? "mx-2 mb-3 border-2 border-brand-secondary-light"
				: ""}`}
		>
			<div className="flex">
				<div className="flex flex-1 flex-col pb-2 md:flex-row md:pb-0">
					{/* <!-- MOBILE version --> */}
					<div className="block p-2 md:hidden">
						<div className=" flex items-center">
							<a href={guidePath} className="shrink-0 p-2" aria-hidden="true">
								<img
									width={56}
									height={56}
									src={pic_url}
									alt={title}
									className={`size-14 rounded-lg${guideTypeId === "d6bf37d8-6cd9-40ae-81ca-ee32f0782be1" && "md:ml-2 lg:ml-0"}`}
								/>
							</a>
							<div className="ml-2 flex flex-col gap-x-4 sm:flex-row">
								<Typography variant="h4" className="mb-2 inline-flex">
									<a href={guidePath}>
										{title}
									</a>
								</Typography>
								{
									is_sponsored && (
										<>
											<span
												data-tooltip-id={`sponsored-tooltip-mobile${id}`}
												data-tooltip-content={
													"Puoi realizzare il progetto scegliendo come cliente l'azienda partner, che potrà vedere il tuo lavoro e contattarti per eventuali opportunità"
												}
											>
												<Tag
													label="Progetto con azienda partner"
													className="cursor-default"
													color="#04B585"
												/>
											</span>
											<Tooltip id={`sponsored-tooltip-mobile${id}`} className=" max-w-[95%] !text-paragraph-span3-mobile md:!text-paragraph-span3-desktop" />
										</>
									)
								}
							</div>
						</div>
						<div className="mb-2 ml-2">
							<CardCourseStats
								/* previously progress?.projects_groups_done > 0 */
								studentCompletedGuide={!!studentCompletedGuide}
								duration_guide={duration_guide}
								duration_project={duration_project}
								max_points_guide={max_points_guide}
								test_points={progress?.test_passed_points}
								test_max_points={progress?.test_max_points}
								projects_count={project_groups_count}
								projects_completed={progress?.projects_groups_done}
								projects_points={progress?.projects_points}
								tests_count={tests_count}
							/>
						</div>
					</div>

					{/* <!-- DESKTOP version --> */}
					<div className={`hidden flex-[3] items-center md:flex ${guideTypeId === "d6bf37d8-6cd9-40ae-81ca-ee32f0782be1" ? "md:p-2" : "p-4"}`}>
						<a href={guidePath} className="shrink-0" aria-hidden="true">
							<img
								width={56}
								height={56}
								src={pic_url}
								alt={title}
								className={`size-14 rounded-lg${guideTypeId === "d6bf37d8-6cd9-40ae-81ca-ee32f0782be1" && "md:ml-2 lg:ml-0"}`}
							/>
						</a>

						<div className="ml-2">
							<div className="mb-1">
								<a href={guidePath}>
									<Typography variant="h4" className="mb-2 inline-flex">
										{title}
									</Typography>
								</a>
								{
									is_sponsored && (
										<>
											<span
												className="ml-4"
												data-tooltip-id={`sponsored-tooltip-desktop${id}`}
												data-tooltip-content={
													"Puoi realizzare il progetto scegliendo come cliente l'azienda partner, che potrà vedere il tuo lavoro e contattarti per eventuali opportunità."
												}
											>
												<Tag
													label="Progetto con azienda partner"
													className="cursor-default"
													color="#04B585"
												/>
											</span>
											<Tooltip id={`sponsored-tooltip-desktop${id}`} className="max-w-[95%] !text-paragraph-span3-mobile md:max-w-[50%] md:!text-paragraph-span3-desktop xl:max-w-[500px]" />
										</>
									)
								}

							</div>

							<CardCourseStats
								studentCompletedGuide={!!studentCompletedGuide}
								duration_guide={duration_guide}
								duration_project={duration_project}
								max_points_guide={max_points_guide}
								test_points={progress?.test_passed_points}
								test_max_points={progress?.test_max_points}
								projects_count={project_groups_count}
								projects_completed={progress?.projects_groups_done}
								projects_points={progress?.projects_points}
								tests_count={tests_count}
							/>
						</div>
					</div>
					{
						isCollapsed && (
							<div className="flex flex-1 px-2">
								<CourseCircularProgress
									guidePath={guidePath}
									theory_progress={progress?.theory_progress}
									test_points={progress?.test_passed_points}
									test_retries={progress?.test_retries}
									test_blocked_until={progress?.test_blocked_until}
									project_blocked_until={progress?.project_blocked_until}
									project_retries={progress?.project_retries}
									project_resend={progress?.project_resend}
									project_sent_date={progress?.project_sent_date}
									projects_count={project_groups_count}
									projects_completed={progress?.projects_groups_done}
									tests_count={tests_count}
									theories_count={theories_count}
								/>
							</div>
						)
					}
				</div>
				<div className="mx-6 mt-6 md:mt-0 md:flex">
					<button
						type="button"
						onClick={() => setIsCollapsed(!isCollapsed)}
						color="link"
						className="my-auto text-greyscale-secondary"
					>
						{isCollapsed ? <FaPlus size={20} /> : <FaMinus size={20} />}
					</button>
				</div>
			</div>

			{
				!isCollapsed && (
					<div className="px-3 pb-4">
						<p
							className="mb-4 text-paragraph-span2-mobile sm:text-paragraph-span2-desktop"
							/* eslint-disable-next-line react/no-danger */
							dangerouslySetInnerHTML={{ __html: description }}
						/>
						{skills.length > 0 || requirements.length > 0 ? (
							<hr className="mb-4 h-0.5 text-pine-100" />
						) : null}

						<div className="items-center justify-between gap-6 px-3 md:flex">
							{skills.length > 0 && (
								<div className="flex-1">
									<Skills skills={skills} />
								</div>
							)}
							{requirements.length > 0 && (
								<div className="mt-6 flex-1 md:mt-0 ">
									<Requirements isFinalProject={isFinalProject} requirements={requirements} />
								</div>
							)}
						</div>
						<hr className="my-4 h-0.5 text-pine-100" />
						<CourseProgressDetails
							keyId={`progress_details_guide_id_${id}`}
							guidePath={`/corsi/${slug}`}
							theory_progress={progress?.theory_progress}
							test_points={progress?.test_passed_points}
							test_max_points={progress?.test_max_points}
							test_retries={progress?.test_retries}
							test_blocked_until={progress?.test_blocked_until}
							project_blocked_until={progress?.project_blocked_until}
							project_retries={progress?.project_retries}
							project_resend={progress?.project_resend}
							project_sent_date={progress?.project_sent_date}
							projects_count={project_groups_count}
							projects_completed={progress?.projects_groups_done}
							projects_points={progress?.projects_points}
							isFinalProject={
								guideTypeId === "1140639d-bbc7-47c7-9477-a2218daf02f2" &&
								id !== "85561517-0834-4772-b935-a6ecf55bd601"
							} // IsFinalProject?'Progetto Finale' OR guideId is "Shopify Advanced"
						/>
					</div>
				)
			}
		</div>
	);
}

export default CardCourseExpandible;
