import { Tooltip } from "react-tooltip";

import { Button, Tag, Typography } from "design2impact";

import CourseCircularProgress from "../circular-progress";
import CoachesList from "../coaches-list";
import CardCourseStats from "../stats";
import { FaCheck } from "react-icons/fa";

import { Course } from "types/defs";
import { ClassNameType } from "types/utils";

interface CardCourseStaticProps {
	className?: ClassNameType
	pic_url: string
	hasTag?: boolean
	course: Course
}

const CardCourseStatic = ({
	className = "",
	pic_url = "",
	hasTag = false,
	course,
}: CardCourseStaticProps) => {
	const {
		title,
		duration_guide,
		duration_project,
		max_points_guide,
		project_groups_count,
		description,
		coaches,
		progress,
		slug,
		topic,
		is_sponsored = false,
		is_in_my_master = false,
		theories_count,
		tests_count,
	} = course;

	const guidePath = `/corsi/${slug?.toLowerCase()}`;
	const studentCompletedGuide = (progress?.projects_points && project_groups_count) || (!project_groups_count && progress?.test_passed_points);

	return (
		<div className={`rounded-lg bg-greyscale-base p-4 shadow ${className}`}>
			<div>
				{/* <!-- MOBILE version --> */}
				<div className="block md:hidden">
					<div className="flex items-center">
						<div>
							<a className="hover:text-greyscale-primary" href={guidePath}>
								<Typography variant="h4">
									{title}
								</Typography>
							</a>
						</div>
					</div>

					{
						is_sponsored && (
							<>
								<span
									className="mt-4"
									data-tooltip-id={`sponsored-tooltip-mobile${slug}`}
									data-tooltip-content={
										"Puoi realizzare il progetto scegliendo come cliente l'azienda partner, che potrà vedere il tuo lavoro e contattarti per eventuali opportunità"
									}
								>
									<Tag
										label="Progetto con azienda partner"
										className="cursor-default"
										color="#04B585"
									/>
								</span>
								<Tooltip id={`sponsored-tooltip-mobile${slug}`} className=" max-w-[95%] !text-paragraph-span3-mobile md:!text-paragraph-span3-desktop" />
							</>
						)
					}

					{hasTag && <Tag className="mt-4" label={topic.name} color={topic.color} />}

					<div className="mb-4">
						<CardCourseStats
							/* previously progress?.projects_groups_done>0 */
							studentCompletedGuide={!!studentCompletedGuide}
							duration_guide={duration_guide}
							duration_project={duration_project}
							max_points_guide={max_points_guide}
							test_points={progress?.test_passed_points}
							test_max_points={progress?.test_max_points}
							projects_count={project_groups_count}
							projects_completed={progress?.projects_groups_done}
							tests_count={tests_count}
							projects_points={progress?.projects_points}
						/>
					</div>
				</div>


				{/* <!-- DESKTOP version --> */}
				<div className="hidden items-center md:flex">
					<div className="shrink-0 py-2 pr-4">
						<a href={guidePath}>
							<img className="rounded-lg" width={56} height={56} src={pic_url} alt={title} />
						</a>
					</div>
					<div className="flex-1 flex-wrap">
						<div className="mb-2 flex">
							<a href={guidePath} className="flex items-center hover:text-greyscale-primary">
								<Typography variant="h4">
									{title}
								</Typography>
							</a>
							{
								is_sponsored && (
									<>
										<span
											className="ml-4"
											data-tooltip-id={`sponsored-tooltip-desktop${slug}`}
											data-tooltip-content={
												"Puoi realizzare il progetto scegliendo come cliente l'azienda partner, che potrà vedere il tuo lavoro e contattarti per eventuali opportunità."
											}
										>
											<Tag
												label="Progetto con azienda partner"
												className="cursor-default"
												color="#04B585"
											/>
										</span>
										<Tooltip id={`sponsored-tooltip-desktop${slug}`} className="!text-paragraph-span3-mobile md:max-w-[50%] md:!text-paragraph-span3-desktop xl:max-w-[500px]" />
									</>
								)
							}
						</div>
						<CardCourseStats
							/* previously progress?.projects_groups_done>0 */
							studentCompletedGuide={!!studentCompletedGuide}
							duration_guide={duration_guide}
							duration_project={duration_project}
							max_points_guide={max_points_guide}
							test_points={progress?.test_passed_points}
							test_max_points={progress?.test_max_points}
							projects_count={project_groups_count}
							tests_count={tests_count}
							projects_completed={progress?.projects_groups_done}
							projects_points={progress?.projects_points}
						/>
					</div>
					{hasTag && topic.name ? (
						<Tag label={topic.name} color={topic.color} />
					) : (
						<div className="flex items-center">
							{is_in_my_master && (
								<div className="mr-4 flex items-center">
									<FaCheck size={20} color="#04B585" />
									<Typography variant="label-2" className="ml-2 text-brand-primary">Incluso nel tuo Master</Typography>
								</div>
							)}
							<Button
								href={guidePath}
								as="external-link"
								size="md"
								type="button"
								className="shrink-0"
								variant={(project_groups_count > 0 && progress?.projects_groups_done === project_groups_count) || is_in_my_master ? "secondary" : "primary"}
							>
								VAI AL CORSO
							</Button>
						</div>
					)}

				</div>
			</div>
			<p dangerouslySetInnerHTML={{ __html: description }} className="-mt-1 inline-block text-paragraph-span2-mobile sm:text-paragraph-span2-desktop md:mt-2" />
			<hr className="my-4 h-0.5 text-pine-100" />
			<div className="grid grid-cols-12 md:gap-6">
				<div className="col-span-12 md:col-span-4">
					<CourseCircularProgress
						guidePath={guidePath}
						theory_progress={progress?.theory_progress}
						test_points={progress?.test_passed_points}
						test_retries={progress?.test_retries}
						test_blocked_until={progress?.test_blocked_until}
						project_blocked_until={progress?.project_blocked_until}
						project_resend={progress?.project_resend}
						project_retries={progress?.project_retries}
						project_sent_date={progress?.project_sent_date}
						projects_count={project_groups_count}
						projects_completed={progress?.projects_groups_done}
						tests_count={tests_count}
						theories_count={theories_count}
					/>
				</div>

				<div className="col-span-12 flex items-center md:col-span-8 md:justify-end">
					{coaches?.length > 0 && <CoachesList coaches={coaches} />}
				</div>
			</div>
			{!hasTag && (
				<>
					<hr className="my-4 h-0.5 text-pine-100 md:hidden" />
					<div className="text-center md:hidden">
						<div className="flex items-center justify-between ">
							{is_in_my_master && (
								<div className="mr-auto flex items-center">
									<FaCheck size={20} color="#04B585" />
									<Typography variant="label-2" className="ml-2 text-left text-brand-primary sm:text-center">Incluso nel tuo Master</Typography>
								</div>
							)}
							<Button
								className="ml-auto shrink-0"
								as="external-link"
								href={guidePath}
								size="md"
								type="button"
								variant={(project_groups_count > 0 && progress?.projects_groups_done === project_groups_count) || is_in_my_master ? "secondary" : "primary"}
							>
								VAI AL CORSO
							</Button>

						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default CardCourseStatic;
