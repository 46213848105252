import { Dispatch, SetStateAction } from "react";
import Cookies from "js-cookie";

import config from "config";

const escapeMarkdown = (text: string) => {
	let result = "";
	let inTripleBacktick = false;

	for (let i = 0; i < text.length; i++) {
		if (text.substring(i, i + 3) === "```") {
			inTripleBacktick = !inTripleBacktick;
			result += "```";
			i += 2; // Salta i prossimi 2 caratteri per non ri-elaborarli
		}
		else if (text[i] === "`" && !inTripleBacktick) {
			result += "\\`";
		}
		else {
			result += text[i];
		}
	}

	return result;
};

const fetchWithToken = async (url: string, method: "GET" | "POST", body: object) => {
	const accessToken = Cookies.get(config.cookieName);
	if (!accessToken) {
		window.location.href = "/login";
		throw new Error("Access token not found, redirecting to login.");
	}

	const response = await fetch(url, {
		method,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(body),
	});

	if (!response.ok) {
		throw new Error(`An error occurred: ${response.statusText}`);
	}

	return response;
};

const handleReadableStream = async (
	response: Response,
	setConversation: Dispatch<SetStateAction<{ isUser: boolean, finish: null | "stop", content: string }[]>>,
	onFinish: () => void,
) => {
	try {
		const reader = response?.body?.getReader();
		if (!reader) throw new Error("Errore nella gestione dello stream");

		const decoder = new TextDecoder();
		let accumulatedChunks = "";

		const processChunk = (chunkString: string) => {
			if (chunkString.startsWith("data: ")) {
				try {
					const jsonStr = chunkString.slice(6);
					const chunk = JSON.parse(jsonStr);

					accumulatedChunks += chunk.content;
					const escapedContent = escapeMarkdown(accumulatedChunks);

					setConversation(prevState => {
						const newState = [...prevState];
						let currentMessage = newState[newState.length - 1];

						if (!currentMessage || currentMessage.finish === "stop") {
							currentMessage = { isUser: chunk.isUser, finish: null, content: "" };
							newState.push(currentMessage);
						}

						currentMessage.content = escapedContent;
						currentMessage.finish = chunk.finish;

						if (chunk.finish === "stop") {
							onFinish();
							accumulatedChunks = ""; // Reset accumulated chunks
						}

						return newState;
					});
				}
				catch (error) {
					console.error("Errore nel parsing JSON del chunk:", chunkString);
				}
			}
		};

		const readStream = async () => {
			const { done, value } = await reader.read();
			if (done) return;

			const decodedValue = decoder.decode(value, { stream: true });
			const chunks = decodedValue.split("\n").filter(chunk => chunk.trim() !== "");

			chunks.forEach(processChunk);

			// Recursive call to read the next chunk
			await readStream();
		};

		await readStream();
	}
	catch (error) {
		onFinish();
		console.error("Errore nella gestione dello stream:", error);
		throw new Error("Errore nella gestione dello stream");
	}
};

export { handleReadableStream, fetchWithToken };
