import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useUserSessionContext from "helpers/useUserSessionContext";
import Cookies from "js-cookie";

import { Loader, Typography } from "design2impact";

import CardPath from "features/masters/card-path.component";
import ModalMasterDetails from "components/modal-master-details";
import TopicSelector from "components/topic-selector";

import { setStudentMaster } from "api/mutations";
import { fetchTopics } from "api/queries";

import { Master, Topic } from "types/defs";
import config from "config";


const getAllMasters = (topics: Topic[]) => topics.flatMap((topic: Topic) => topic.masters);

const Masters = () => {
	const { userSession } = useUserSessionContext();
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();

	const [mastersList, setMasters] = useState<Master[]>([]);
	const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null); // the topicId selected by the filter
	const [selectedMaster, setSelectedMaster] = useState<Master | null>(); // the masterId to be showed in modal

	const { data: topics, isLoading, error } = useQuery({
		queryKey: ["topics", userSession?.id],
		queryFn: () => fetchTopics(userSession?.id),
		enabled: !!userSession?.id,
	});

	const { mutate: setMasterMutation } = useMutation({
		mutationKey: ["currentMaster", userSession?.id],
		mutationFn: setStudentMaster,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["studentData", userSession?.id] });
			navigate(config.basepath);
		},
	});

	useEffect(() => {
		if (topics && topics.length > 0) setMasters(getAllMasters(topics));
	}, [topics]);

	const filterMasterByTopic = (topic_id: string | null) => {
		if (topics && topics.length > 0) {
			let mastersByTopic = topic_id !== null && topics.find((topic) => topic.id === topic_id)?.masters;
			if (!mastersByTopic) {
				mastersByTopic = getAllMasters(topics);
			}
			setMasters(mastersByTopic);
			setSelectedTopicId(topic_id);
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = (masterId: string, mastersList: Master[]) => {
		const selectedMaster = mastersList.find(master => master.id === masterId);
		setSelectedMaster(selectedMaster);
		setIsModalOpen(!isModalOpen);
	};

	const startNewMaster = (masterId: string) => {
		setMasterMutation({ userId: userSession?.id, masterId });
	};

	useEffect(() => {
		if (location.pathname === config.onboardingPath) {
			const cookie = Cookies.get("data-layer-trial");
			if (cookie) {
				const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
				const dataLayer = JSON.parse(cookie);
				TagManager.dataLayer({
					dataLayer: {
						event: "utente_prova_talent",
						provaDetails: dataLayer,
					},
				});
				Cookies.remove("data-layer-trial", { domain });
			}
		}
	}, [location]);

	if (isLoading || error) return <Loader />;

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[1].title}</title>
			</Helmet>

			<div className="relative min-h-screen bg-ui-secondary pt-4 text-greyscale-primary">
				<div className="container">
					<div className="ml-auto grid grid-cols-12 gap-6">
						<div className="col-span-12 flex justify-center">
							{
								location.pathname === config.onboardingPath && (
									<div className="col-span-12 text-center">
										<img src={`${config.assetsPath}images/logos/full.png`} width="191" height="40" alt="start2impact logo" />
									</div>
								)
							}
						</div>
					</div>

					<div className="mb-6 grid grid-cols-12">
						<div className="col-span-12 text-center">
							<Typography variant="h1">
								{location.pathname === config.onboardingPath ? "Scegli il tuo Master" : "Seleziona un Master"}
							</Typography>
						</div>


						<div className="col-span-12 my-3 mt-8 text-center">
							<Typography variant="paragraph-p1" className="inline-block max-w-screen-sm">
								Solo tu puoi sapere qual &egrave; il percorso più adatto a te. Scegli quello pi&ugrave; affine alle tue passioni e ai tuoi interessi.
							</Typography>

							<Typography variant="paragraph-p1" className="mt-2 inline-block max-w-screen-sm">
								Potrai cambiarlo in qualsiasi momento, tutte le volte che vuoi.
							</Typography>
						</div>
					</div>

					{topics && topics.length > 0 ? <TopicSelector topics={topics} onSelectTopicFn={filterMasterByTopic} selectedTopicId={selectedTopicId} /> : null}

					{
						mastersList?.length > 0
							? (
								<div className="mt-8 grid grid-cols-12 gap-6">
									{
										mastersList.map(master => {
											return (
												<div key={`path_${master.id}`} className="col-span-12 pb-2">
													<CardPath
														toggleModal={() => toggleModal(master.id, mastersList)}
														startNewPath={() => startNewMaster(master.id)}
														{...master}
													/>
												</div>
											);
										})
									}
								</div>
							)
							: (<div className="rounded bg-brand-primary/20 p-4 text-brand-secondary">Nessun Master trovato.</div>)
					}
				</div>

				{isModalOpen && selectedMaster && (
					<ModalMasterDetails
						isModalOpen={isModalOpen}
						toggle={() => setIsModalOpen(false)}
						master={selectedMaster}
						startNewMaster={startNewMaster}
					/>
				)}
			</div>
		</>
	);
};


export default Masters;
