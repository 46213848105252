import { Carousel } from "react-responsive-carousel";

import { date } from "helpers2impact";

import ProjectProgress from "./project-progress.component";
import TestProgress from "./test-progress.component";
import TheoryProgress from "./theory-progress.component";

import { Nullable } from "types/utils";


const doughnutSetting = {
	cutout: "70%",
	plugins: {
		legend: { display: false, position: "right" },
		tooltip: { enabled: false },
	},
	elements: { arc: { borderWidth: 1 } },
	rotation: 360,
	circumference: 360 / 1,
};

interface RenderContentProps {
	isMobile: boolean
	children: any
	keyId: string
	openProjectCarouselItem: boolean
	theoryProgress: number
	isFinalProject: boolean
}

const RenderContent = ({
	isMobile = false,
	children,
	keyId,
	openProjectCarouselItem = false,
	theoryProgress = 0,
	isFinalProject = false,
}: RenderContentProps) => {
	return (
		<div className="flex gap-6 md:px-3" key={keyId}>
			{isMobile ?
				isFinalProject ? children : (
					<Carousel
						showThumbs={false}
						showStatus={false}
						selectedItem={isFinalProject ? 2 : theoryProgress !== 100 ? 0 : openProjectCarouselItem ? 2 : 1}
					>
						{children}
					</Carousel>
				) : (
					<>
						{children}
						{
							isFinalProject && (
								<>
									<div className="w-full" />
									<div className="w-full" />
								</>
							)
						}
					</>
				)}
		</div>
	);
};

interface CourseProgressDetailsProps {
	keyId: string,
	guidePath: string
	theory_progress: number
	test_points: number
	test_max_points: number
	test_retries: number
	test_blocked_until: Nullable<string>
	project_blocked_until: Nullable<string>
	project_retries: number
	project_resend: boolean
	project_sent_date: Nullable<boolean>
	projects_count: number
	projects_completed: number
	projects_points: string | null
	isFinalProject: boolean
}


const CourseProgressDetails = ({
	keyId = "",
	guidePath = "",
	theory_progress = 0,
	test_points = 0,
	test_max_points = 0,
	test_retries = 0,
	test_blocked_until = null,
	project_blocked_until = null,
	project_retries = 0,
	project_resend = false,
	project_sent_date = null,
	projects_count = 0,
	projects_completed = 0,
	projects_points = "",
	isFinalProject = false,
}: CourseProgressDetailsProps) => {
	const isMobile = window.innerWidth < 768;

	// check if project is available and test is completed
	const openProjectCarouselItem = () => {
		return (
			!(project_sent_date !== null
				|| (project_blocked_until !== null && date.calculateHoursToNow(project_blocked_until) > 0)
				|| (projects_completed < projects_count && test_points === 0))
		);
	};

	return (
		<RenderContent
			isMobile={isMobile}
			keyId={keyId}
			openProjectCarouselItem={openProjectCarouselItem()}
			theoryProgress={theory_progress}
			isFinalProject={isFinalProject}
		>
			<TheoryProgress
				isMobile={isMobile}
				theory_progress={theory_progress}
				isFinalProject={isFinalProject}
				guidePath={guidePath}
				doughnutSetting={doughnutSetting}
			/>

			<TestProgress
				isMobile={isMobile}
				test_max_points={test_max_points}
				testPoints={test_points}
				test_retries={test_retries}
				theory_progress={theory_progress}
				guidePath={guidePath}
				isFinalProject={isFinalProject}
				test_blocked_until={test_blocked_until}
				doughnutSetting={doughnutSetting}
			/>

			<ProjectProgress
				isMobile={isMobile}
				guidePath={guidePath}
				isFinalProject={isFinalProject}
				project_blocked_until={project_blocked_until}
				project_resend={project_resend}
				project_sent_date={project_sent_date}
				projects_completed={projects_completed}
				projects_count={projects_count}
				projects_points={projects_points}
				project_retries={project_retries}
				test_points={test_points}
				doughnutSetting={doughnutSetting}
			/>
		</RenderContent>
	);
};

export default CourseProgressDetails;
