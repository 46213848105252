import { http } from "helpers2impact";

import { FetchCourseResponse, FetchNotificationsResponse, FetchStudentResponse, FetchTopicsResponse, FetchUserResponse } from "types/api";
import { Nullable } from "types/utils";
import config from "config";


const fetchRanking = async (userId: string) => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/v1/students/${userId}/ranking`);

	return response.data.entities.ranking;
};

const fetchTopics = async (userId: string): Promise<FetchTopicsResponse> => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/students/${userId}/topics`);

	return response.data.entities.topics.items.data;
};

const fetchCourses = async (): Promise<FetchCourseResponse> => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/v2/students/me/courses`);

	return ({
		courses: response.data.courses,
		topics: response.data.topics,
	});
};

const fetchSysOnuObjectives = async () => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/sys/onuobjectives`);

	return response.data.entities.onu_objectives.items.data;
};

const fetchSysWorkingSectors = async () => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/sys/workingsectors`);

	return response.data.entities.working_sectors.items.data;
};

const fetchNotifications = async (userId: string): Promise<FetchNotificationsResponse> => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.notificationsApiUrl}/users/${userId}/notifications/?limit=25`);

	return response.data.notifications;
};

const fetchRenewalData = async (userId: string) => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/users/${userId}/subscription/stripe/has-payment-method`);
	return response.data.entities;
};

const fetchBadges = async (userId: string) => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.badgeApiUrl}/api/v1/users/${userId}/badges`);

	return response.data.arr;
};

const fetchStudentData = async (userId: Nullable<string>): Promise<FetchStudentResponse> => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/students/${userId}`);

	return response.data.entities.student.item;
};

// Rails
const fetchSubscription = async (userId: string) => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.authenticationApiUrl}/users/${userId}/ensure_payment`);

	return response.data;
};
// End Rails

const fetchUser = async (userId: Nullable<string>): Promise<FetchUserResponse> => {
	const response = await http(config.cookieName, config.loginPath).get(`${config.apiUrl}/users/${userId}`);

	return response.data.entities.user.item;
};


export {
	fetchCourses,
	fetchRanking,
	fetchTopics,
	fetchSysOnuObjectives,
	fetchSysWorkingSectors,
	fetchNotifications,
	fetchRenewalData,
	fetchBadges,
	fetchStudentData,
	fetchSubscription,
	fetchUser,
};
