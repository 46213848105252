import { useCallback, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import gfm from "remark-gfm";

import { Typography } from "design2impact";

import { FetchUserResponse } from "types/api";
import { Nullable } from "types/utils";
import config from "config";

type Message = {
	isUser: boolean;
	finish: null | "stop";
	content: string;
}

interface ChatProps {
	conversation: Message[];
	currentUser: Nullable<FetchUserResponse>;
}

const Chat = ({ conversation, currentUser }: ChatProps) => {
	const chatContainerRef = useRef<HTMLDivElement>(null);
	const [stopScrollToBottom, setStopScrollToBottom] = useState<boolean>(false);


	const scrollToBottom = useCallback(() => {
		if (chatContainerRef.current && !stopScrollToBottom) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [stopScrollToBottom]);

	const handleScroll = useCallback(() => {
		if (chatContainerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
			if (scrollTop + clientHeight < scrollHeight - 10) {
				setStopScrollToBottom(true);
			}
			else {
				setStopScrollToBottom(false);
			}
		}
	}, []);

	useEffect(() => {
		const chatContainer = chatContainerRef.current;
		if (chatContainer) {
			chatContainer.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (chatContainer) {
				chatContainer.removeEventListener("scroll", handleScroll);
			}
		};
	}, [handleScroll]);

	useEffect(() => {
		scrollToBottom();
	}, [conversation, scrollToBottom]);


	if (!currentUser) {
		return null;
	}


	return (
		<div ref={chatContainerRef} className="grow overflow-y-auto">
			<div className="container mx-auto">
				{conversation.map((message, index) => {
					if (!message.isUser) {
						return (
							<div key={index + Math.random()} className="flex gap-x-6 [&:not(:last-child)]:mb-14">
								<div className="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full border border-brand-secondary-light shadow">
									<img width={32} height={32} src={`${config.assetsPath}images/logos/mini.svg`} alt="Logo Start2impact" />
								</div>
								<Markdown
									remarkPlugins={[gfm]}
									className="markdown-content overflow-x-auto font-paragraph text-paragraph-span1-mobile scrollbar-none md:text-paragraph-span1-desktop"
									components={{
										// eslint-disable-next-line react/no-unstable-nested-components
										code({ children, className }) {
											const match = /language-(\w+)/.exec(className || "");
											return match ? (
												<SyntaxHighlighter
													PreTag="div"
													language={match[1]}
													className="overflow-x-auto"
												>
													{String(children).replace(/\n$/, "")}
												</SyntaxHighlighter>
											) : (
												<code className={className}>
													{children}
												</code>
											);
										},
									}}
								>
									{message.content}
								</Markdown>
							</div>
						);
					}

					return (
						<div key={index + Math.random()} className="ml-auto flex w-2/3 items-center justify-end gap-x-6 scrollbar-none md:w-full [&:not(:last-child)]:mb-14">
							<Typography variant="paragraph-span1" className="rounded-lg bg-brand-primary-light px-5 py-4">
								{message.content}
							</Typography>
							<div className="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full bg-blue-10 shadow">
								<img
									width={48}
									height={48}
									src={currentUser.pic_profile?.url ? `${config.cloudinaryFetchPath}w_256/${currentUser.pic_profile.url}` : "https://res.cloudinary.com/start2impact/image/upload/v1666605907/Piattaforma/Img_Profilo-04_envhln.svg"}
									alt="Immagine profilo utente"
									className="size-full min-h-12 rounded-full object-cover"
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Chat;
