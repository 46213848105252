

import { Button, Modal, Typography } from "design2impact";

import CourseCardList from "./course-card-list.component";

import { Master } from "types/defs";
import config from "config";

interface ModalMasterDetailsProps {
	master: Master
	toggle: () => void
	isModalOpen: boolean
	startNewMaster: (id: string) => void
}

const ModalMasterDetails = ({
	toggle,
	isModalOpen,
	master,
	startNewMaster,
}: ModalMasterDetailsProps) => {
	const {
		id,
		title,
		courses_count,
		projects_count,
		theories_hours,
		projects_hours,
		responsible,
		topic,
		containers,
		student_infos,

	} = master;
	return (
		<div className="min-h-screen bg-greyscale-base py-[30px] text-greyscale-primary">
			<Modal
				isVisible={isModalOpen}
				toggleModalFn={toggle}
				className="max-w-[57.5rem] !p-6 lg:p-8"
				title={`Programma del Master ${title}`}
			>

				<div>
					<div className="mt-8 grid grid-cols-12 gap-4 text-greyscale-primary md:gap-x-6">
						<div id="theories_count" className="col-span-12 md:col-span-6 lg:col-span-3">
							<div className="inline-flex w-full items-center rounded-lg border-2 border-solid border-greyscale-primary px-4 py-1.5">
								<Typography variant="h3" className="mr-2 shrink-0">{courses_count}</Typography>
								<Typography variant="label-2" className="shrink-0">course Teoriche</Typography>
							</div>
						</div>
						<div id="projects_count" className="col-span-12 md:col-span-6 lg:col-span-3">
							<div className="inline-flex w-full items-center rounded-lg border-2 border-solid border-greyscale-primary px-4 py-1.5">
								<Typography variant="h3" className="mr-2 shrink-0">{projects_count}</Typography>
								<Typography variant="label-2" className="shrink-0">Progetti Pratici</Typography>
							</div>
						</div>
						<div id="theories_hours" className="col-span-12 md:col-span-6 lg:col-span-3">
							<div className="inline-flex w-full items-center rounded-lg border-2 border-solid border-greyscale-primary px-4 py-1.5">
								<Typography variant="h3" className="mr-2 shrink-0">{theories_hours}</Typography>
								<Typography variant="label-2" className="shrink-0">Ore di Teoria</Typography>
							</div>
						</div>
						<div id="projects_hours" className="col-span-12 md:col-span-6 lg:col-span-3">
							<div className="inline-flex w-full items-center rounded-lg border-2 border-solid border-greyscale-primary px-4 py-1.5">
								<Typography variant="h3" className="mr-2 shrink-0">{projects_hours}</Typography>
								<Typography variant="label-2" className="shrink-0">Ore di Pratica</Typography>
							</div>
						</div>
					</div>

					<div className="mt-6 rounded-lg bg-brand-primary-light px-3 py-4 text-greyscale-primary">
						<div className="grid grid-cols-12 items-center md:gap-6 lg:gap-1">
							<div className="col-span-12 md:col-span-4">
								<img
									className="h-[150px] w-full overflow-hidden rounded-lg object-cover object-top"
									style={{ backgroundColor: topic.color }}
									src={`${config.cloudinaryFetchPath}${responsible?.pic_url}`}
									alt={`Immagine profilo di ${responsible.user.firstname}`}
								/>
							</div>
							<div className="col-span-12 px-2 md:col-span-8">
								<Typography variant="h4" className="mb-1 mt-4 md:mt-0">
									{responsible.user.firstname}
									{" "}
									{responsible.user.lastname}
								</Typography>
								<Typography variant="paragraph-span1" className="mb-3">
									Responsabile
									{" "}
									{topic.name}
								</Typography>
								<Typography variant="paragraph-span2">{responsible.user.bio}</Typography>
							</div>
						</div>
					</div>

					<Typography variant="h3" className="my-6">Corsi</Typography>
					<div className="-mx-4 h-[300px] overflow-y-scroll px-4">
						<CourseCardList containers={containers} />
					</div>
				</div>

				<div className="-mx-8 flex h-full justify-center border-t border-t-greyscale-primary/20">
					{
						(student_infos === null || student_infos?.progress < 100) && (
							<Button as="button" className="mb-2 mt-4" onClick={() => startNewMaster(id)}>
								{student_infos?.is_current ? "Riprendi" : "INIZIA MASTER"}
							</Button>
						)
					}
				</div>
			</Modal>
		</div>
	);
};

export default ModalMasterDetails;
