

import config from "config";


interface LogoProps {
	basepath: string
	isCollapsed: boolean
	className?: string
	type: "black" | "white"
}

const Logo = ({
	basepath = config.assetsPath,
	isCollapsed = false,
	className = "",
	type = "black",
}: LogoProps) => {
	return (
		<div className={`flex justify-center pt-12 ${className}`}>
			<a href="/" className="flex justify-center">
				{
					isCollapsed ? <img src={`${basepath}images/logos/mini.svg`} width="41" height="41" alt="start2impact logo" className="size-[40px]" />
						: type === "black" ? <img src={`${basepath}images/logos/full.png`} width="191" height="40" alt="start2impact logo" className="h-[40px] w-[191px]" />
							: <img src={`${basepath}images/logos/fullWhite.png`} width="191" height="40" alt="start2impact logo" className="h-[40px] w-[191px]" />
				}
			</a>
		</div>
	);
};


export default Logo;
