

import { Tag, Typography } from "design2impact";

import { FaArrowDown, FaArrowUp, FaEquals } from "react-icons/fa";

import { StudentRanking } from "types/defs";
import config from "config";


const cloudinaryPath = `${config.cloudinaryFetchPath}h_160/`;

interface CardStudentRankingProps {
	currentUserSlug: string
	student: StudentRanking
}

const CardStudentRanking = ({
	currentUserSlug = "",
	student,
}: CardStudentRankingProps) => {
	const { firstname, lastname, slug, rank, master, pic_profile } = student;
	const studentImage = pic_profile ? cloudinaryPath + pic_profile : "https://res.cloudinary.com/start2impact/image/upload/v1666605907/Piattaforma/Img_Profilo-04_envhln.svg";

	return (
		<div className={`mb-4 grid grid-cols-12 items-center gap-y-4 rounded-lg bg-greyscale-base p-4 shadow md:grid-cols-10 md:flex-row md:items-center md:px-6 lg:grid-cols-12 ${slug === currentUserSlug && "ring-4 ring-inset ring-mint-500"}`}>
			<div className="col-span-12 grid grid-cols-8 items-center md:col-span-5 lg:col-span-6">
				<div className="col-span-7 grid grid-cols-7 items-center sm:grid-cols-11 lg:grid-cols-10">
					<div className="hidden md:col-span-2 md:block md:text-left lg:col-span-1">
						<Typography variant="label-1">
							{rank?.current?.pos}
							.
						</Typography>
					</div>
					<div className="col-span-2 mr-6 md:col-span-3 md:mr-0 lg:col-span-2">
						<a href={`profile/${slug}`}>
							<img
								className="size-16 min-w-[64px] rounded-full object-cover"
								width={64}
								height={64}
								src={studentImage}
								alt="Foto profilo dell'utente"
							/>
						</a>
					</div>
					<Typography variant="label-1" className="col-span-4 sm:col-span-9 md:col-span-5 lg:-m-1.5">
						<a href={`profile/${slug}`}>
							{firstname}
							{" "}
							{lastname}
						</a>
					</Typography>
				</div>

				<div className="col-span-1 block text-end md:hidden">
					<Typography variant="paragraph-span1" className="text-greyscale-secondary">
						{rank?.current?.pos}
						.
					</Typography>
				</div>
			</div>


			<hr className="order-4 col-span-12 text-pine-100 md:hidden" />


			<div className="order-5 col-span-6 md:col-span-3 md:my-0 lg:col-span-4">
				<Tag color={master?.topic?.color} label={master?.title} />
			</div>

			<div className="order-6 col-span-6 flex items-center justify-end gap-x-6 md:col-span-2">
				{slug === currentUserSlug ? (
					<>
						{rank?.current?.pos === rank?.previous?.pos && <FaEquals className="text-feedback-waiting" />}
						{rank?.current?.pos < rank?.previous?.pos && <FaArrowUp className="text-feedback-success" />}
						{rank?.current?.pos > rank?.previous?.pos && <FaArrowDown className="text-feedback-error" />}
					</>
				)
					: null}

				<Typography variant="paragraph-span1" className="text-greyscale-secondary">
					{rank?.current?.points}
					{" "}
					pt
				</Typography>
			</div>
		</div>
	);
};

export default CardStudentRanking;
