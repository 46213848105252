import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Loader, Typography } from "design2impact";

import Logo from "components/logo";
import ModalMasterDetails from "components/modal-master-details";
import TopicSelector from "components/topic-selector";
import CardMaster from "./card-master.component";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RxCheckCircled } from "react-icons/rx";

import { fetchTopics } from "api/queries";

import { Master, Topic } from "types/defs";
import config from "../../config";


const getAllMasters = (topics: Topic[]) => topics.flatMap(topic => topic.masters);


const Upselling = () => {
	const { userSession } = useUserSessionContext();

	const [masters, setMasters] = useState<Master[]>([]);
	const [selectedMaster, setSelectedMaster] = useState<Master | null>(null);
	const [selectedTopicId, setSelectedTopicId] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { data: topics, isLoading, error } = useQuery({
		queryKey: ["topics", userSession?.id],
		queryFn: () => fetchTopics(userSession?.id),
		enabled: !!userSession?.id,
	});

	const openModalMasterDetails = (master: Master) => {
		setSelectedMaster(master);
		setIsModalOpen(true);
	};


	const filterMasterByTopic = (topic_id: string) => {
		if (topics && topics.length > 0) {
			let mastersByTopic = topic_id !== null && topics.find(topic => topic.id === topic_id)?.masters;
			if (!mastersByTopic) {
				mastersByTopic = getAllMasters(topics);
			}
			setMasters(mastersByTopic);
			setSelectedTopicId(topic_id);
		}
	};

	useEffect(() => {
		if (topics) setMasters(getAllMasters(topics));
	}, [topics]);

	if (isLoading || error) return <Loader />;

	return (
		<>
			<Helmet>
				<title>Inizia il tuo Master</title>
			</Helmet>

			<div className="min-h-screen bg-greyscale-base text-center text-greyscale-primary">
				<header className="bg-greyscale-primary px-4 font-bold text-greyscale-base">
					<div className="container p-0">
						<Logo
							className="flex justify-center py-3"
							basepath={config.basepath}
							isCollapsed={false}
							type="white"
						/>

						<div className="px-0 pb-20 pt-14">
							<Typography variant="h1" className="text-brand-primary">Master in</Typography>
							<Typography variant="h2">
								Digital Marketing, Full Stack, UX/UI Design,
								{" "}
								<br />
								Data Science e Blockchain
							</Typography>

							<div className="my-8">
								<div className="flex justify-center">
									<Typography variant="label-1" className="mr-2 !font-bold text-feedback-error line-through">897€</Typography>
									<Typography variant="h1" className="text-brand-base"> 747€ per 1 anno </Typography>
								</div>
								<Typography variant="h4">poi 299€/anno senza vincoli di rinnovo</Typography>
							</div>

							<div className="mx-auto mb-8 mt-0 grid max-w-[980px] grid-cols-12 rounded-lg bg-brand-base/[0.15] px-8 pt-8 text-left text-brand-primary">
								<div className="col-span-12 md:col-span-6">
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Non serve laurea o esperienza</Typography>
									</div>
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Progetti individuali su casi reali</Typography>
									</div>
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Correzioni da professionisti</Typography>
									</div>
								</div>

								<div className="col-span-12 md:col-span-6">
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Supporto finché non trovi il lavoro adatto a te</Typography>
									</div>
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Gruppi studio online e Meetup dal vivo</Typography>
									</div>
									<div className="mb-8 flex items-center">
										<RxCheckCircled size={24} className="size-6 shrink-0" />
										<Typography variant="label-1" className="ml-4 !font-bold">Puoi cambiare Master, senza costi aggiuntivi</Typography>
									</div>
								</div>
							</div>

							<Typography variant="h4">
								Potrai pagare anche in 3 rate da 249€ senza interessi con Scalapay
							</Typography>

						</div>
					</div>
				</header>

				<main className="pt-20">
					<div className="container pb-20">
						<Typography variant="h2"> Scegli il tuo master </Typography>
						<div className="mb-8 mt-6">
							{topics && (
								<TopicSelector
									onSelectTopicFn={filterMasterByTopic}
									selectedTopicId={selectedTopicId}
									topics={topics}
								/>
							)}
						</div>

						<div className="mx-auto max-w-[1140px]">
							<div className="grid grid-cols-12 gap-8 ">
								{masters.length > 0 &&
									masters.map((master) => {
										return (
											<div
												className="col-span-12 md:col-span-6 xl:col-span-4"
												key={`master${master.id}`}
											>
												<CardMaster
													openModalMasterDetails={openModalMasterDetails}
													master={master}
												/>
											</div>
										);
									})}
							</div>
						</div>
					</div>
					{isModalOpen && selectedMaster && (
						<ModalMasterDetails
							isModalOpen={isModalOpen}
							toggle={() => setIsModalOpen(false)}
							master={selectedMaster}
							startNewMaster={() => (window.location.href = "/checkout?plan=m1-o27")}
						/>
					)}

					{/* FAQ */}

					<section className="bg-ui-secondary py-20">
						<div className="container">
							<Typography variant="h2">FAQ</Typography>

							<div className="mx-auto mt-14 flex max-w-[720px] flex-col items-center gap-4 ">
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">Ho qualche agevolazione avendo già acquistato il Piano di Orientamento? </Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												Sì. Il valore totale del Piano Orientamento (150€) verrà scalato dal prezzo totale dell&apos;abbonamento e quindi pagherai solo 747€.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">Come funzionano i Corsi dei Master?</Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												I corsi all’interno di ogni Master sono costituiti da 3 sezioni: guida, test e progetto pratico.
												<br />
												<br />
												Nella guida è presente la parte teorica, costituita dalle migliori risorse gratuite online a livello internazionale.
												<br />
												<br />
												Non trovi video corsi originali, ma una selezione di contenuti come video e articoli tra i più autorevoli sul web,
												perché oggi le fonti sono già disponibili gratuitamente.
												<br />
												<br />
												Esternalizzare la teoria è ciò che ci permette di rendere start2impact accessibile a tutti
												e concentrare le nostre energie sull’esperienza pratica, garantendo una qualità difficilmente reperibile altrove.
												<br />
												<br />
												Puoi smettere di pagare per contenuti tradotti in italiano che sono disponibili gratis in inglese.
												<br />
												<br />
												Per questo motivo per la formazione teorica hai a disposizione fonti prevalentemente in inglese e altre in italiano.
												<br />
												<br />
												Se non sai bene l’inglese, non preoccuparti, non serve essere madrelingua e considera lo studio delle fonti in inglese
												come un’occasione per migliorare le tue competenze linguistiche.
												<br />
												<br />
												Se vuoi lavorare nel digitale è importante sviluppare in questi mesi un buon livello di inglese,
												altrimenti ti troverai in difficoltà quando inizierai a lavorare.
												<br />
												<br />
												Questo perché nel digitale tutte le principali documentazioni di software, tool o aggiornamenti sono in lingua inglese.
												<br />
												<br />
												Impari a formarti e aggiornarti come fanno i migliori professionisti: autonomi e indipendenti nella propria crescita.
												<br />
												<br />
												Al termine dello studio teorico è presente un test per valutare ciò che hai imparato.
												<br />
												<br />
												Superato il test puoi mettere in pratica quanto appreso con il progetto pratico.
												<br />
												<br />
												Entro 7 giorni dall’invio del progetto ricevi una correzione personalizzata da un professionista
												del settore che valuta i punti di forza e quelli su cui migliorare per farti crescere passo dopo passo.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">Quanto durano i Master?</Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												Dato che decidi tu quando e quanto studiare, la risposta più corretta e trasparente è dipende da Master a Master.
												<br />
												<br />
												Puoi approfondire la durata di ognuno entrando nella sezione &ldquo;Scopri di più&ldquo; delle card dedicate ai vari Master.
												<br />
												<br />
												L’abbonamento è annuale per coprire anche i tempi per la ricerca di lavoro e perché cerchiamo
												persone che vogliano crescere insieme a noi nel lungo periodo per avere un impatto positivo sulla società e sul pianeta.
												<br />
												<br />
												Per noi start2impact non è solo studio, pratica e lavoro, ma soprattutto creazione di relazioni.
												<br />
												<br />
												Per questo organizziamo ogni mese eventi dal vivo e online con studenti, professionisti e aziende per crescere continuamente a livello umano e professionale.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">I Master mi aiuteranno a trovare lavoro? </Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												Sì. Per aiutarti a trovare lavoro in un’azienda adatta alle tue esigenze quello che facciamo è suddiviso in 3 step.
												<br />
												<br />
												<strong>1. Costruisci un Portfolio di Progetti individuali in cui ricevi correzioni da professionisti del settore. </strong>
												Acquisisci così un vantaggio competitivo nei colloqui perché sei in grado di mostrare le tue competenze.
												<br />
												<br />
												<strong>2. Dopo aver finito il Master, hai accesso al Career Service. </strong>
												Ti prepariamo ad entrare nelle migliori aziende grazie a video call 1-to-1 con figure Senior,
												per migliorare il tuo CV, le tue candidature e simulare il colloquio.
												<br />
												<br />
												<strong>
													3. Per tutta la durata della tua ricerca di lavoro hai una persona dedicata a supportarti ogni settimana via email per darti feedback e migliorare.
													{" "}
												</strong>
												L’abbonamento alla formazione ha durata di 1 anno, mentre il supporto nella ricerca del lavoro è sempre disponibile una volta che hai finito il Master.
												Ti seguiamo letteralmente finché non trovi il lavoro adatto a te.
												<br />
												<br />
												<strong>
													Questi step ad oggi hanno garantito a tutti i nostri studenti, anche partendo da zero e con background totalmente diversi,
													di ottenere uno stage retribuito di 6 mesi.
												</strong>
												<br />
												<br />
												<strong>Il 98% è stato poi confermato</strong>
												{" "}
												con un contratto di apprendistato, poi trasformato in indeterminato.
												Gli altri sono stati supportati fino a trovare nuove opportunità, anche con l’abbonamento scaduto, perché per noi le persone vengono prima del profitto.
												<br />
												<br />
												<strong>Trovare l’azienda giusta per te però dipende anzitutto dal tuo impegno. </strong>
												Noi ti segnaliamo alle aziende partner e ti seguiamo con il Career Service,
												ma dovrai impegnarti attivamente anche fuori dal nostro network per aumentare le tue opportunità.
												<br />
												<br />
												Come avrai già capito, non siamo il Master adatto a chi cerca qualcuno che si impegni al posto suo e per questo non possiamo dirti in anticipo dove
												e quando farai lo stage, perché dipenderà anche dalla tua ricerca e dai tuoi obiettivi.
												<br />
												<br />
												<strong>Il cambiamento che vogliamo portare nel mondo della formazione</strong>
												{" "}
												è poter acquisire competenze
												con un investimento fino a 20 volte più accessibile e con una mentalità che faccia crescere nel lungo periodo.
												<br />
												<br />
												<strong>Trovare lavoro in questo senso è una conseguenza delle competenze e della mentalità che sviluppi. </strong>
												Questo è il valore più grande che i nostri studenti ci riconoscono e su questa strada vogliamo continuare a migliorarci.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">Posso iniziare i Master anche se parto da zero oppure ho studiato altro nella vita?</Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												I Master sono pensati per partire dalle competenze di base fino ad arrivare a quelle più avanzate,
												che ti permetteranno di avere un livello tale da considerarti una figura Junior nel settore in cui hai deciso di formarti.
												<br />
												<br />
												Si possono intraprendere quindi anche partendo da zero o con background diversi,
												come testimoniano le diverse storie di successo di membri della Community che hanno poi trovato lavoro e che puoi approfondire
												{" "}
												<a target="_blank" className="underline" href="https://www.start2impact.it/category/blog/storie-di-successo/" rel="noreferrer">qui.</a>
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">Posso scegliere un solo Master?</Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												No. Avrai tutti i Master inclusi nell&apos;abbonamento, perciò anche scegliendone uno in fase di iscrizione,
												una volta dentro la piattaforma potrai cambiarlo in qualsiasi momento e fare tutti i Master che vorrai.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
								<Disclosure>
									{({ open }) => (
										<div className={`flex w-full flex-col items-center rounded-lg text-left shadow ${open ? "bg-brand-primary-light" : "bg-greyscale-base"}`}>
											<DisclosureButton className="flex w-full items-center p-4 text-left font-medium">
												<div className={`mr-4 flex size-8 shrink-0 items-center justify-center rounded-full${open ? "bg-greyscale-base" : "bg-brand-primary"}`}>
													{open ? <FaMinus size={12} color="#04B585" /> : <FaPlus size={12} color="white" />}
												</div>
												<Typography variant="label-1">È possibile seguire i Master se già studio o lavoro?</Typography>
											</DisclosureButton>
											<DisclosurePanel as="p" className="pb-6 pl-16 pr-4 pt-2 text-left">
												Sì, su start2impact non hai nessun vincolo o limite di tempo.
												Ogni attività è disponibile h24 e 7 su 7 all&apos;interno della piattaforma, dallo studio della teoria alla pratica dei progetti.
											</DisclosurePanel>
										</div>
									)}
								</Disclosure>
							</div>
						</div>
					</section>
				</main>
			</div>
		</>
	);
};


export default Upselling;
