import { ReactNode } from "react";

import { Typography } from "design2impact";

import { FaBookmark, FaGraduationCap, FaRandom } from "react-icons/fa";


const iconType = (type: string) => {
	switch (type) {
		case "Corso Opzionale":
			return <FaBookmark className="mr-1 size-5" />;
		case "Scegli uno o più Corsi tra:":
			return <FaRandom className="mr-1 size-5" />;
		case "Progetto Finale":
			return <FaGraduationCap className="mr-1 size-5" />;
		default:
			return null;
	}
};

interface ContainerWithLabelProps {
	type: string
	id: string
	displayLabel: boolean
	children: ReactNode
}

const ContainerWithLabel = ({ type, id, displayLabel, children }: ContainerWithLabelProps) => {
	return (
		<div
			key={`home-course-${id}`}
			className="!mb-4 overflow-hidden rounded-[10px] bg-greyscale-base shadow"
		>
			<Typography variant="label-2" className={`${displayLabel ? "flex items-center px-4 pb-3 pt-4 text-brand-secondary" : "hidden"}`}>
				{iconType(type)}
				{" "}
				{type}
			</Typography>

			{children}
		</div>
	);
};

export default ContainerWithLabel;
