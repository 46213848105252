import { Tooltip } from "react-tooltip";

import { Typography } from "design2impact";

import { Coach } from "types/defs";
import { Nullable } from "types/utils";
import config from "config";


const CoachesList = ({ coaches }: { coaches: Nullable<Coach[]> }) => {
	return (
		<div className="mt-2 flex flex-col text-greyscale-secondary md:flex-row md:content-end md:items-center">
			<div className="pr-2 md:text-right">
				<Typography variant="paragraph-span3">{coaches && coaches.length > 1 ? "Correggeranno i tuoi Progetti:" : "Correggerà i tuoi Progetti:"}</Typography>
			</div>

			<div className="mt-4 flex max-w-full gap-2 overflow-auto md:mt-0">
				{coaches && coaches.length > 0 && coaches.map(
					(coach, index) => coach.pic_profile?.url && (
						<div key={`div_coach_${coach.id + index}`} className="shrink-0">
							<span
								data-tooltip-id={`coach-tooltip-${coach.id}`}
								data-tooltip-content={`${coach.firstname} ${coach.lastname}`}
							>
								<img
									className="rounded-full"
									src={coach.pic_profile?.url ? `${config.cloudinaryFetchPath}c_fill,g_face,h_60,w_60/r_max/f_auto/${coach.pic_profile.url}` : "https://via.placeholder.com/60"}
									alt={`${coach.firstname} ${coach.lastname}`}
									width={42}
									height={42}
								/>
							</span>
							<Tooltip place="top" id={`coach-tooltip-${coach.id}`} className="max-w-[95%] !text-paragraph-span3-mobile md:!text-paragraph-span3-desktop" />
						</div>
					),
				)}
			</div>
		</div>
	);
};


export default CoachesList;
