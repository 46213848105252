import dayjs from "dayjs";

import { colors } from "helpers2impact";

import { Nullable } from "types/utils";


export const getProjectDetails = (
	test_points: number,
	project_blocked_until: Nullable<string>,
	project_retries: number,
	project_resend: boolean,
	project_sent_date: Nullable<boolean>,
	projects_count: number,
	projects_completed: number,
	projects_points: string | null,
	isFinalProject: boolean,
) => {
	let textCopy: string = "";
	let isButtonActive = false;

	const hoursBlocked = dayjs(project_blocked_until).diff(new Date(), "hour");
	const minutesBlocked = dayjs(project_blocked_until).diff(new Date(), "minute");

	let projectTimeBlockedLabel = "";

	if (hoursBlocked >= 24) {
		projectTimeBlockedLabel = `tra ${Math.round(hoursBlocked / 24)} ${Math.round(hoursBlocked / 24) === 1 ? "giorno" : "giorni"}`;
	}
	else if (hoursBlocked >= 1) {
		projectTimeBlockedLabel = `tra ${hoursBlocked} ${hoursBlocked === 1 ? "ora" : "ore"}`;
	}
	else if (minutesBlocked > 0 && minutesBlocked < 60) {
		projectTimeBlockedLabel = "a breve";
	}

	if (project_sent_date !== null) { // Progetto in attesa di correzione
		textCopy = "Progetto inviato, riceverai un feedback entro 7 giorni";
		isButtonActive = false;
	}
	else if (project_resend === true) { // Progetto da reinviare
		textCopy = "Progetto da reinviare";
		isButtonActive = true;
	}
	else if (projectTimeBlockedLabel) { // 3 volte bocciato, deve aspettare per riprovare
		textCopy = `Progetto non superato<br />Potrai riprovare ${projectTimeBlockedLabel}`;
		isButtonActive = true;
	}
	else if (project_blocked_until !== null && hoursBlocked <= 0) { // 3 volte bocciato ma può riprovare
		textCopy = "Progetto non superato<br />Riprova!";
		isButtonActive = true;
	}
	else if (project_retries === 2) { // Progetto con bocciatura, 2 tentativi rimanenti
		textCopy = "Progetto non superato con 2 tentativi rimanenti";
		isButtonActive = true;
	}
	else if (project_retries === 1) { // Progetto con bocciatura, 1 tentativi rimanenti
		textCopy = "Progetto non superato con 1 tentativo rimanente";
		isButtonActive = true;
	}
	else if (projects_count > 0 && (projects_completed === projects_count)) { // tutti i progetti sono stati svolti
		let projectPoints = null;
		if (projects_completed === 1) {
			projectPoints = projects_points && projects_points.indexOf(":") > -1 ? projects_points.split(":") : [];
			textCopy = projectPoints !== null ? `Progetto ${projectPoints[0]}/${projectPoints[1]}` : "Errore";
		}
		else {
			const projects = projects_points && projects_points.indexOf(":") > -1 ? projects_points.split(",") : [];
			textCopy = projects.map((points, i) => `Progetto ${i + 1} - ${points.split(":")[0]}/${points.split(":")[1]}<br />`).join("");
		}
		isButtonActive = true;
	}
	else if (projects_count === 1 && projects_completed === 0 && (test_points > 0 || isFinalProject)) { // Progetto non svolto
		textCopy = "Progetto da completare";
		isButtonActive = true;
	}
	else if (projects_completed < projects_count && test_points === 0) { // test non completato
		textCopy = "Potrai fare il progetto una volta completato il test";
		isButtonActive = false;
	}
	else if (projects_completed < projects_count) { // progetto non completato
		textCopy = projects_count - projects_completed === 1 ? "1 Progetto rimanente" : `${projects_count - projects_completed} Progetti rimanenti`;
		isButtonActive = true;
	}
	else {
		textCopy = "Questo non era previsto! Ce lo segnali? :)"; // bug
		isButtonActive = false;
	}
	return {
		data: projects_count === 1 ? [100] : [...Array(projects_count)].map(() => 100 / projects_count),
		backgroundColor: (context: any) => colors.getProjectStatusColor(
			context,
			projects_completed,
			project_sent_date,
			project_resend,
			project_blocked_until,
			project_retries,
			projects_count,
		),
		icon: [...Array(projects_count)].map((_x, i) => {
			return (
				projects_completed >= i + 1
					? "fill-[url(#lgrad)]"
					: (project_sent_date !== null)
						? "text-feedback-waiting"
						: (project_resend === true)
							? "text-feedback-warning"
							: (project_blocked_until !== null || project_retries > 0)
								? "text-feedback-error"
								: "text-[#777]");
		}),
		text: textCopy,
		button: isButtonActive,
	};
};
