import { useEffect, useRef, useState } from "react";
import { BigPlayButton, Player, PlayerProps, PlayerReference } from "video-react"; // https://video-react.js.org/

import "video-react/dist/video-react.css";

interface VideoPlayerProps extends PlayerProps {
	onVideoStart: () => void
	onVideoEnd: () => void
	playsInline?: boolean
}


const VideoPlayer = ({ onVideoStart, onVideoEnd, playsInline = false, ...props }: VideoPlayerProps) => {
	const videoRef = useRef<PlayerReference>(null);
	const [videoState, setVideoState] = useState<any>(null);
	const [hasStarted, setHasStarted] = useState<any>(false);
	const [hasEnded, setHasEnded] = useState<any>(false);

	// Copying the player's state to a local component state on every change
	useEffect(() => {
		videoRef?.current?.subscribeToStateChange(setVideoState);
	}, [setVideoState]);

	// Checking if the video has started on every state update
	useEffect(() => {
		if (videoState && videoState.hasStarted && !hasStarted) {
			// Preventing onVideoEnd from being called multiple times
			setHasStarted(true);
			onVideoStart();
		}
	}, [videoState, hasStarted, setHasStarted, onVideoStart]);

	// Checking if the video has ended on every state update
	useEffect(() => {
		if (videoState && videoState.ended && !hasEnded) {
			// Preventing onVideoEnd from being called multiple times
			setHasEnded(true);
			onVideoEnd();
		}
	}, [videoState, hasEnded, setHasEnded, onVideoEnd]);

	return (
		<Player ref={videoRef} playsInline={playsInline} {...props}>
			<BigPlayButton position="center" />
		</Player>
	);
};

export default VideoPlayer;
