import { Helmet } from "react-helmet";
import { useCustomerly } from "react-live-chat-customerly";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Loader, MobileToggleIcon, Typography } from "design2impact";

import CardStudent from "features/student-home/card-student.component";
import NotificationsBell from "containers/notifications-bell";
import useViewport from "../../helpers/useViewport";
import GuideCardList from "./guide-card-list.component";
import IntroBanner from "./intro-banner.component";

import { fetchStudentData, fetchSysOnuObjectives, fetchSysWorkingSectors } from "api/queries";

import { FetchStudentResponse } from "types/api";
import { Master, OnuObjective, WorkingSector } from "types/defs";
import config from "../../config";

const StudentHome = () => {
	const { userSession } = useUserSessionContext();
	const { width } = useViewport();
	const isMobile = width < 1200;

	const { event, update } = useCustomerly();

	// Update user's properties in customerly crm
	if (config.isProduction) {
		// eslint-disable-next-line no-undef, no-unused-vars, @typescript-eslint/no-unused-vars
		const customerlyConfig = update({
			user_id: userSession?.id,
			name: userSession.firstname,
			email: userSession.email,
		});
		// eslint-disable-next-line no-undef, no-unused-vars, @typescript-eslint/no-unused-vars
		const homepageEvent = event("homepage_loaded");
	}

	const { data: all_ob, isLoading: onuObjectivesLoading, error: onuObjectivesError } = useQuery({
		queryKey: ["onuObjectives"],
		queryFn: () => fetchSysOnuObjectives(),
	});

	const { data: all_ws, isLoading: workingSectorsLoading, error: workingSectorsError } = useQuery({
		queryKey: ["workingSectors"],
		queryFn: () => fetchSysWorkingSectors(),
	});

	const { data: student_info, isLoading: studentDataLoading, error: studentDataError } = useQuery<FetchStudentResponse, AxiosError>({
		queryKey: ["studentData", userSession?.id],
		queryFn: () => fetchStudentData(userSession?.id),
		enabled: !!userSession?.id,
		retry: false,
	});

	const modalWorkingSectors = all_ws && all_ws.length > 0 &&
		all_ws.map((ws: WorkingSector) => (student_info?.user?.working_sectors?.find((stud_ws) => ws.id === stud_ws.id)
			? { ...ws, ...{ is_selected: true } }
			: ws));
	const modalOnuObjectives = all_ob && all_ob.length > 0 &&
		all_ob.map((ob: OnuObjective) => (student_info?.user?.onu_objectives?.find((stud_ob) => ob.id === stud_ob.id)
			? { ...ob, ...{ is_selected: true } }
			: ob));

	const introCourseContainer = student_info?.master?.containers?.filter((container: Master["containers"][0]) => container?.details?.[0]?.course?.is_intro);
	const nonIntroCoursesContainers = student_info?.master?.containers?.filter((container: Master["containers"][0]) => !container?.details?.[0]?.course?.is_intro);

	const isIntroCourseCompleted = introCourseContainer && introCourseContainer[0]?.details[0]?.course?.progress?.projects_groups_done
		>= introCourseContainer?.[0]?.details?.[0]?.course?.project_groups_count;

	const hasFirstProjectPoints = nonIntroCoursesContainers && nonIntroCoursesContainers?.[0]?.details?.[0]?.course?.progress?.projects_groups_done > 0;

	const showIntroCourse = introCourseContainer && introCourseContainer.length > 0 && !isIntroCourseCompleted && !hasFirstProjectPoints;

	if (studentDataError?.response?.status === 404) {
		window.location.href = config.onboardingPath;
	}

	if (
		studentDataLoading ||
		(studentDataError && studentDataError?.response?.status !== 404) ||
		workingSectorsLoading || onuObjectivesError || workingSectorsError || onuObjectivesLoading
	) {
		return <Loader />;
	}

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[0].title}</title>
				<link rel="canonical" href={`https://www.start2impact.it/${config.basepath}`} />
				<script>
					{`
						(function() {
							if (typeof customerly === "function") {
								customerlyConfig;
								homepageEvent;
							}
						})()
					`}
				</script>
			</Helmet>

			<div className="flex min-h-screen flex-col justify-between bg-ui-secondary text-greyscale-primary">
				<div className="container py-6">
					{/* Page Header */}
					<div className="mb-8 flex items-center justify-between px-3">
						<Typography variant="h2">
							Ciao
							{" "}
							{student_info?.user.firstname}
						</Typography>
						<div className="flex">
							{
								isMobile && (
									<MobileToggleIcon />
								)
							}
							{userSession.plan_slug !== "m1-o26" && (
								<NotificationsBell />
							)}
						</div>
					</div>

					{/* Page Body */}
					{
						// display only when the plan is "orientamento"
						!(userSession.plan_slug === "m1-o26") && student_info && (
							<CardStudent
								student={student_info}
								workingSectors={modalWorkingSectors}
								onuObjectives={modalOnuObjectives}
								userId={userSession.id}
							/>
						)
					}

					<section className="mt-10">
						{userSession.plan_slug !== "m1-o26" ? (
							<>
								<Typography variant="h3" className="mb-6  text-greyscale-primary">I Corsi del tuo Master:</Typography>

								<IntroBanner
									isShown={!!showIntroCourse}
									picUrl={`${introCourseContainer?.[0]?.details?.[0]?.course?.image?.url ?
										config.cloudinaryFetchPath + introCourseContainer[0].details[0].course.image.url : "https://via.placeholder.com/56"}`}
									courseUrl={introCourseContainer?.[0]?.details?.[0]?.course?.slug || ""}
								/>
							</>
						) : null}


						<GuideCardList
							containers={userSession.plan_slug === "m1-o26" ? student_info?.master?.containers : nonIntroCoursesContainers}
						/>
					</section>
				</div>
			</div>
		</>
	);
};

export default StudentHome;
