

import { Button, Typography } from "design2impact";

interface IntroBannerProps {
	isShown: boolean
	picUrl: string
	courseUrl: string
}

const IntroBanner = ({
	isShown,
	picUrl,
	courseUrl,
}: IntroBannerProps) => {
	if (!isShown) return null;


	return (
		<div className="mb-3 flex flex-col items-center gap-6 rounded-lg bg-greyscale-base p-4 shadow sm:flex-row">
			<div>
				<div className="mb-2 flex items-center gap-6">
					<a
						href={courseUrl}
						aria-hidden="true"
						className="shrink-0"
					>
						<img width="56" height="56" src={picUrl} alt="" className="size-14 rounded-lg" />
					</a>

					<a
						href={courseUrl}
					>
						<Typography variant="h4" className="text-brand-primary">
							Fai il primo passo
						</Typography>
					</a>
				</div>

				<Typography variant="paragraph-p1" className="text-greyscale-secondary">Parti dal Corso introduttivo per fare pratica già dopo poche ore di studio e ricevere una correzione da professionisti del settore.</Typography>
			</div>

			<Button
				as="external-link"
				className="flex shrink-0 justify-normal text-center"
				href={`/corsi/${courseUrl}`}
			>
				Inizia ora
			</Button>
		</div>
	);
};

export default IntroBanner;
