
import { Doughnut } from "react-chartjs-2";
import { ChartData, ScriptableContext } from "chart.js";

import { Button, Typography } from "design2impact";
import { colors } from "helpers2impact";

import { FaBook } from "react-icons/fa";

import styles from "./style.module.css";

interface TheoryProgressProps {
	isMobile: boolean
	theory_progress: number
	isFinalProject: boolean
	guidePath: string
	doughnutSetting: any
}


const TheoryProgress = ({ isMobile, theory_progress, isFinalProject, guidePath, doughnutSetting }: TheoryProgressProps) => {
	const isNotCompleted = theory_progress !== 100;
	const theoryData: ChartData<"doughnut", number[], string> | ((canvas: HTMLCanvasElement) => ChartData<"doughnut", number[], string>) = {
		labels: theory_progress === 0 ? ["Guida da completare"] : (theory_progress === 100) ? ["Guida completata"] : [`Guida completata al ${theory_progress}%`],
		datasets: [
			{
				data: theory_progress > 0 ? [theory_progress, 100 - theory_progress] : [100],
				// eslint-disable-next-line max-len
				backgroundColor: (context: ScriptableContext<"doughnut">): any => (theory_progress > 0 ? [colors.createGradient(context), colors.progressColors.inactiveBackgroundColor] : colors.progressColors.inactiveBackgroundColor),
				hoverBorderColor: ["transparent"],
			},
		],
	};


	return (
		!isMobile ? (
			!isFinalProject && (
				<div className={styles.detailCourseContainer}>
					<div className={styles.detailCourseLabelContainer}>
						<div className="relative w-14">
							<Doughnut data={theoryData} options={doughnutSetting} width={80} />
							<div>
								<FaBook
									className={`${styles.chartIcon} ${theory_progress === 100 ? styles.iconGreen : styles.iconGray}`}
								/>
							</div>
						</div>
						<div className="ml-4">
							<ul>
								{theoryData?.labels?.map((label, index) => (
									<li key={`doughnut_theory_key_${index}`}>
										<Typography variant="paragraph-span2">{label}</Typography>
									</li>
								))}
							</ul>
						</div>
					</div>
					<Button
						variant={isNotCompleted ? "primary" : "secondary"}
						as="external-link"
						href={`${guidePath}#course`}
						size="md"
					>
						VAI ALLA GUIDA
					</Button>
				</div>
			)

		) : (
			!isFinalProject && (
				<div className={styles.carouselMobileItemContainer}>
					<div className={styles.carouselMobileItemLabelContainer}>
						<div className="relative col-span-2 w-14">
							<Doughnut data={theoryData} options={doughnutSetting} width={80} />
							<div>
								<FaBook
									className={`${styles.chartIcon} ${theory_progress === 100 ? styles.iconGreen : styles.iconGray}`}
								/>
							</div>
						</div>
						<div className="col-span-10 pl-4">
							<ul>
								{theoryData?.labels?.map((label, index) => (
									<li key={`doughnut_theory_key_${index}`}>
										<p className="text-paragraph-span2-mobile sm:text-paragraph-span2-desktop" dangerouslySetInnerHTML={{ __html: label }} />
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="mt-auto grid grid-cols-12">
						<Button
							variant={isNotCompleted ? "primary" : "secondary"}
							className="col-span-12"
							as="external-link"
							href={`${guidePath}#course`}
							size="md"
						>
							VAI ALLA GUIDA
						</Button>
					</div>
				</div>
			)
		)
	);
};


export default TheoryProgress;
