import { http } from "helpers2impact";

import { UpdateOnuObjectivesResponse, UpdateWorkingSectorsResponse } from "types/api";
import { OnuObjective, WorkingSector } from "types/defs";
import { Nullable } from "types/utils";
import config from "config";


const setBadgeAsSeen = async (badgePath: Nullable<string>) => {
	const response = await http(config.cookieName, config.loginPath).post(`${config.badgeApiUrl}${badgePath}?not_show_again=true`);

	return response.data;
};

const setNotificationAsRead = async ({ userId, notificationId }: { userId: Nullable<string>, notificationId: string }) => {
	const response = await http(config.cookieName, config.loginPath)
		.post(`${config.notificationsApiUrl}/users/${userId}/notifications/${notificationId}/mark_notification_as_read`);

	return response;
};

const setAllNotificationsAsRead = async (userId: Nullable<string>) => {
	const response = await http(config.cookieName, config.loginPath)
		.post(`${config.notificationsApiUrl}/users/${userId}/notifications/mark_all_notifications_as_read`);

	return response;
};

const hadAccess = async (userId: Nullable<string>) => {
	const response = await http(config.cookieName, config.loginPath).post(`${config.authenticationApiUrl}/users/${userId}/had_access`);
	const lastAccess = new Date();

	return response.data.message === "Success" ? lastAccess : null;
};

const setStudentMaster = async ({ userId, masterId }: { userId: Nullable<string>, masterId: string }) => {
	const response = await http(config.cookieName, config.loginPath).post(`${config.apiUrl}/students/${userId}/masters`, { master_id: masterId });

	return response.data;
};

const updateOnuObjectives = async ({ userId, list }: { userId: Nullable<string>, list: OnuObjective[] }): Promise<UpdateOnuObjectivesResponse> => {
	const response = await http(config.cookieName, config.loginPath).put(`${config.apiUrl}/students/${userId}/onuobjectives`, list);

	return response.data.entities.onu_objectives;
};

const updateWorkingSectors = async ({ userId, list }: { userId: Nullable<string>, list: WorkingSector[] }): Promise<UpdateWorkingSectorsResponse> => {
	const response = await http(config.cookieName, config.loginPath).put(`${config.apiUrl}/students/${userId}/workingsectors`, list);

	return response.data.entities.working_sectors;
};

const neverShowWelcomeVideo = async (userId: Nullable<string>) => {
	const response = await http(config.cookieName, config.loginPath).post(`${config.apiUrl}/students/${userId}/onboarding-video`);

	return response.data.entities.updated_item.item.onboarding_video_showed;
};


export {
	setNotificationAsRead,
	setAllNotificationsAsRead,
	setBadgeAsSeen,
	hadAccess,
	setStudentMaster,
	updateOnuObjectives,
	updateWorkingSectors,
	neverShowWelcomeVideo,
};
