import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Loader, Typography } from "design2impact";

import CardStudentRanking from "./card-student.component";

import { fetchRanking } from "api/queries";

import { StudentRanking } from "types/defs";
import config from "config";

const Ranking = () => {
	const { userSession } = useUserSessionContext();
	const [isTop10, setIsTop10] = useState(false);

	const { data: ranking, isLoading, error } = useQuery({
		queryKey: ["ranking", userSession?.id],
		queryFn: () => fetchRanking(userSession?.id),
		enabled: !!userSession?.id,
	});

	useEffect(() => {
		if (ranking && ranking?.list?.data?.length > 0) {
			if (ranking?.student_out_of_ranking) {
				setIsTop10(true);
			}
			else {
				setIsTop10(ranking.is_in_top10);
			}
		}
	}, [ranking]);

	const renderColumnInfo = () => {
		return (
			<div className="mb-4 hidden grid-cols-12 px-6 text-brand-base md:grid md:grid-cols-10 lg:grid-cols-8">
				<Typography variant="paragraph-span2" className="col-span-4 md:col-span-3 md:!col-start-3 lg:col-span-3 lg:!col-start-2">Nome e Cognome</Typography>
				<Typography variant="paragraph-span2" className="col-span-3 ">Master</Typography>
			</div>
		);
	};

	if (isLoading || error || !ranking) return <Loader />;

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[3].title}</title>
			</Helmet>
			<div className="container mx-auto min-h-screen bg-ui-secondary py-10">
				<header className="mb-14 flex items-center justify-between gap-x-2.5">
					<div>
						<Typography variant="h2" className="mb-1">Classifica</Typography>
						<Typography variant="label-2">
							Gli studenti e le studentesse di
							{" "}
							<span style={{ color: ranking.me?.data?.master?.topic?.color }}>{ranking.me?.data?.master?.topic?.name}</span>
							{" "}
							che hanno ottenuto più punti negli ultimi 30 giorni
						</Typography>
					</div>
				</header>

				<main>
					{ranking?.list?.data?.length > 0 && ranking.list.data.map((student: StudentRanking, index: number) => {
						return (
							<div key={student.slug + index}>
								{index === 0 && (
									renderColumnInfo()
								)}
								{!isTop10 && index === 5 ? (
									<div>
										<Typography variant="h2" className="mb-8 mt-14">La tua posizione</Typography>
										{renderColumnInfo()}
									</div>
								) : null}
								<CardStudentRanking
									currentUserSlug={ranking.me?.data?.slug}
									student={student}
								/>
							</div>
						);
					})}
				</main>
			</div>
		</>
	);
};

export default Ranking;
