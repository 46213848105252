import { Doughnut } from "react-chartjs-2";
import { Tooltip } from "react-tooltip";

import { Button } from "design2impact";

import { getTestDetails } from "../tests";
import { FaInfoCircle, FaTasks } from "react-icons/fa";

import { Nullable } from "types/utils";

import styles from "./style.module.css";

interface TestProgressProps {
	isMobile: boolean
	theory_progress: number
	testPoints: number
	test_retries: number
	test_max_points: number
	test_blocked_until: Nullable<string>
	isFinalProject: boolean
	guidePath: string
	doughnutSetting: any
}

const TestProgress = ({
	isMobile,
	theory_progress,
	testPoints,
	test_retries,
	test_max_points,
	test_blocked_until,
	isFinalProject,
	guidePath,
	doughnutSetting,
}: TestProgressProps) => {
	const testDetails = getTestDetails(theory_progress, testPoints, test_retries, test_max_points, test_blocked_until);

	const testData = {
		labels: [testDetails.text],
		colors: testDetails.colors,
		datasets: [
			{
				data: [100],
				backgroundColor: (context: any) => testDetails.colors.circleBackgroundColor(context),
				hoverBorderColor: ["transparent"],
			},
		],
	};

	return (
		// Desktop
		!isMobile ? (
			!isFinalProject && (
				<div className={styles.detailCourseContainer}>
					<div className={styles.detailCourseLabelContainer}>
						<div>
							<div className="relative w-14">
								<Doughnut data={testData} options={doughnutSetting} width={80} />
								<div>
									<FaTasks className={`${styles.chartIcon} ${testData.colors.iconCssBackground}`} />
								</div>
							</div>
						</div>
						<div className="ml-4">
							<ul>
								{testData.labels.map((label, index) => (
									<li key={`doughnut_test_key_${label + index}`} className="flex items-center">
										<p
											className={styles.detailCourseLabel}
											/* eslint-disable-next-line react/no-danger */
											dangerouslySetInnerHTML={{ __html: label }}
										/>
										<div>
											{test_retries > 0 && test_retries < 3 && testPoints === 0 && (
												<div className="ml-2">
													<span
														data-tooltip-id={`test-desktop-tooltip-${label + index}`}
														data-tooltip-content={`Dopo che finirai i tentativi dovrai attendere
															${testDetails.backendTestBlockAfterNRetries}
															giorni per tentare nuovamente questo test`}
													>
														<FaInfoCircle className="mx-4 shrink-0 text-[19px] text-[#ccc]" />
													</span>
													<Tooltip id={`test-desktop-tooltip-${label + index}`} className="z-[999] max-w-[300px] !text-paragraph-span3-mobile md:!text-paragraph-span3-desktop" />
												</div>
											)}
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>

					<Button
						disabled={!testDetails.button}
						size="md"
						as="external-link"
						href={`${guidePath}#test`}
					>
						VAI AL TEST
					</Button>
				</div>
			)
		) : (
			// Mobile
			!isFinalProject && (
				<div className={styles.carouselMobileItemContainer}>
					<div className={`${styles.carouselMobileItemLabelContainer}`}>
						<div className="relative col-span-2 w-14">
							<Doughnut data={testData} options={doughnutSetting} width={80} />
							<FaTasks className={`${styles.chartIcon} ${testData.colors.iconCssBackground}`} />
						</div>

						<div className="col-span-10 pl-4">
							{test_retries > 0 && test_retries < 3 && testPoints === 0 ? (
								<ul>
									{testData.labels.map((label, index) => (
										<li className="flex items-center" key={`doughnut_test_key_${label + index}`}>
											<div
												className={styles.detailCourseLabel}
												/* eslint-disable-next-line react/no-danger */
												dangerouslySetInnerHTML={{ __html: label }}
											/>

											{test_retries > 0 && test_retries < 3 && testPoints === 0 && (
												<>
													<span
														className="ml-4"
														data-tooltip-id={`test-desktop-tooltip-${label + index}`}
														data-tooltip-content={`Dopo che finirai i tentativi dovrai attendere
															${testDetails.backendTestBlockAfterNRetries}
															giorni per tentare nuovamente questo test`}
													>
														<FaInfoCircle className="shrink-0 text-[19px] text-[#ccc]" />
													</span>
													<Tooltip place="bottom" id={`test-desktop-tooltip-${label + index}`} className="z-[999] max-w-[60%] !text-paragraph-span3-mobile !opacity-100 md:!text-paragraph-span3-desktop" />
												</>
											)}
										</li>
									))}
								</ul>
							) : (
								<ul>
									<li key="doughnut_test_key_1">
										<div
											className={styles.detailCourseLabel}
											/* eslint-disable-next-line react/no-danger */
											dangerouslySetInnerHTML={{ __html: testData.labels[0] }}
										/>
									</li>
								</ul>
							)}
						</div>
					</div>
					<Button
						disabled={!testDetails.button}
						size="md"
						as="external-link"
						href={`${guidePath}#test`}
					>
						VAI AL TEST
					</Button>
				</div>
			)
		)
	);
};

export default TestProgress;
