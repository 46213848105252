import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "context/auth-context";
import PortalRenewalAlert from "portals/alerts/renewal-alert";
import PortalBadge from "portals/badges/badge";

import LayoutSidebar from "layouts/sidebar";
import LayoutWithoutSidebar from "layouts/without-sidebar";
import Error404 from "features/404";
import Masters from "features/masters";
import StudentHome from "features/student-home";
import Upselling from "features/upselling";
import ModalWelcomeVideo from "containers/modal-welcome-video";

import config from "config";

import "react-responsive-carousel/lib/styles/carousel.min.css";


const Orient = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<ModalWelcomeVideo />
				<PortalBadge />
				<PortalRenewalAlert />
				<Routes>
					<Route path={`${config.basepath}`} element={<LayoutSidebar />}>
						<Route index element={<StudentHome />} />
					</Route>
					<Route path={`${config.basepath}`} element={<LayoutWithoutSidebar />}>
						<Route path={config.onboardingPath} element={<Masters />} />
						<Route path={`${config.basepath}inizia-master`} element={<Upselling />} />
					</Route>
					<Route path="*" element={<Error404 />} />
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	);
};

export default Orient;
