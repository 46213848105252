import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import useUserSessionContext from "helpers/useUserSessionContext";
import Cookies from "js-cookie";

import { Loader, Typography, useToast } from "design2impact";

import Logo from "components/logo";
import Chat from "./chat.component";
import { IoSend } from "react-icons/io5";

import { handleReadableStream } from "./api";
import { fetchUser } from "api/queries";

import config from "config";


const TutorAi = () => {
	const [searchParams] = useSearchParams();
	const esercizio = searchParams.get("esercizio");
	const { userSession } = useUserSessionContext();
	const [loading, setLoading] = useState<boolean>(true);
	const [conversation, setConversation] = useState<{ isUser: boolean, finish: null | "stop", content: string }[]>([]);
	const [conversationId, setConversationId] = useState<string | null>(null);
	const [tutorIsWriting, setTutorIsWriting] = useState<boolean>(false);
	const [closeConversation, setCloseConversation] = useState<boolean>(false);
	const [inputError, setInputError] = useState<string>("");

	const { showToast } = useToast();
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const [requestText, setRequestText] = useState<string>("");

	const { data: user, isLoading: userLoading, error: userError } = useQuery({
		queryKey: ["fetchUser", userSession.id],
		queryFn: () => fetchUser(userSession.id),
		enabled: !!userSession.id,
	});

	const sendConversationRequest = async (requestText: string) => {
		try {
			const accessToken = Cookies.get(config.cookieName);
			if (accessToken) {
				const response = await fetch(`${config.apiUrl}/v2/tutor-ai/conversations/${conversationId}`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({
						isStream: true,
						message: requestText,
					}),
				});

				if (!response.ok) {
					if (response.status !== 400) {
						throw new Error("Si è verificato un errore, per favore riprova");
					}
					const errorData = await response.json();
					if (errorData?.errors[0]?.constraints[0] === "isLength") {
						throw new Error("Il messaggio non può superare i 50.000 caratteri");
					}
					else throw new Error("Si è verificato un errore, per favore riprova");
				}

				const conversationClosedFlag = response.headers.get("X-Conversation-End");
				if (conversationClosedFlag === "true") {
					setCloseConversation(true);
				}
				setConversation(prevState => [
					...prevState,
					{ isUser: false, finish: null, content: "" },
				]);

				setTutorIsWriting(true);
				await handleReadableStream(response, setConversation, () => setTutorIsWriting(false));
			}
			else {
				window.location.href = "/login";
			}
		}
		catch (error: any) {
			setTutorIsWriting(false);
			showToast(error.message, "error");
		}
	};

	const startConversation = useCallback(async (slug: string | undefined | null) => {
		setLoading(false);

		try {
			const accessToken = Cookies.get(config.cookieName);
			if (accessToken) {
				const response = await fetch(`${config.apiUrl}/v2/tutor-ai/conversations`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({
						exerciseIdentifier: slug || "",
						isStream: true,
					}),
				});

				if (!response.ok) {
					throw new Error("Si è verificato un errore, prova a ricaricare la pagina");
				}

				const conversationId = response.headers.get("X-Conversation-Id");
				setConversationId(conversationId);

				setConversation([{ isUser: false, finish: null, content: "" }]);

				setTutorIsWriting(true);
				await handleReadableStream(response, setConversation, () => setTutorIsWriting(false));
			}
			else {
				window.location.href = "/login";
			}
		}
		catch (error: any) {
			showToast(error.message, "error");
		}
	}, [showToast]);


	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setTutorIsWriting(true);

		setConversation(prevState => {
			const newState = [...prevState];
			newState.push({ isUser: true, finish: null, content: requestText });
			return newState;
		});

		sendConversationRequest(requestText);
		setRequestText("");
	};

	useEffect(() => {
		startConversation(esercizio);
	}, [esercizio, startConversation]);

	// Auto fit text area input height
	useEffect(() => {
		const adjustHeight = () => {
			const textarea = textareaRef.current;
			if (textarea) {
				textarea.style.height = "auto";
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();

		// Set errors
		if (requestText.length > config.tutorAImessageMaxLenght) {
			setInputError("Il testo non può superare i 50.000 caratteri");
		}
		if (requestText.length <= config.tutorAImessageMaxLenght && inputError) {
			setInputError("");
		}
	}, [inputError, requestText]);

	if (loading || userError || userLoading) {
		return <Loader />;
	}

	return (
		<div className="flex h-screen flex-col">
			<div className="flex h-20 shrink-0 items-center justify-center bg-greyscale-primary">
				<Logo className="!pt-0" basepath={config.assetsPath} isCollapsed={false} type="white" />
			</div>
			<div className="flex grow flex-col overflow-hidden pt-10">
				<Chat conversation={conversation} currentUser={user} />
				<div className="bg-greyscale-base">
					<div className="container mx-auto py-10">
						<form onSubmit={onSubmit} className="relative flex w-full">
							<textarea
								ref={textareaRef}
								disabled={tutorIsWriting || closeConversation}
								onChange={(e) => setRequestText(e.target.value)}
								className="max-h-40 min-h-[58px] w-full resize-none rounded-lg border-2 border-brand-secondary-light bg-brand-primary-light
								px-6 py-4 pr-16 font-paragraph !text-paragraph-p1-mobile scrollbar-none focus:border-brand-secondary-light
								focus:ring-0 focus:ring-[transparent] focus-visible:outline-none md:!text-paragraph-p1-desktop"
								placeholder="Scrivi qui"
								value={requestText}
								rows={1}
							/>

							<button
								disabled={!requestText || tutorIsWriting || !!inputError || closeConversation}
								aria-label="Invia la richiesta"
								type="submit"
								className="absolute bottom-3 right-4"
							>
								<IoSend
									size={32}
									className={clsx(
										!requestText || tutorIsWriting || inputError || closeConversation ? "text-brand-primary/50" : "text-brand-primary hover:text-brand-secondary",
									)}
								/>
							</button>
						</form>
						<Typography className="absolute mt-2 block text-feedback-error" variant="paragraph-span3">
							{inputError}
						</Typography>
					</div>

				</div>
			</div>
		</div>
	);
};

export default TutorAi;
