import { Doughnut } from "react-chartjs-2";

import { colors } from "helpers2impact";

import { FaBook, FaFileInvoice, FaTasks } from "react-icons/fa";

import { Nullable } from "types/utils";

import styles from "./style.module.css";


const doughnutSetting = {
	maintainAspectRatio: true,
	responsive: true,
	cutout: "70%",
	plugins: {
		legend: { display: false, position: "right" as const },
		tooltip: { enabled: false },
	},
	elements: {
		arc: { borderWidth: 1 },
		rotation: 0,
		circumference: 360 / 1,
	},
};

interface GuideCircularProgressProps {
	theory_progress?: number
	test_points?: number
	test_retries?: number
	test_blocked_until?: Nullable<string>
	project_blocked_until?: Nullable<string>
	project_retries?: number
	project_resend?: boolean
	project_sent_date?: Nullable<boolean>
	projects_count?: number
	projects_completed?: number
	tests_count?: number
	theories_count?: number
	guidePath?: string,
}


const GuideCircularProgress = ({
	theory_progress = 0,
	test_points = 0,
	test_retries = 0,
	test_blocked_until = null,
	project_blocked_until = null,
	project_retries = 0,
	project_resend = false,
	project_sent_date = null,
	projects_count = 0,
	projects_completed = 0,
	tests_count = 0,
	theories_count = 0,
	guidePath = "",
}: GuideCircularProgressProps) => {
	const projectsDataset = {
		data: projects_count > 1 ? [...Array(projects_count)].map(() => 100 / projects_count) : [100],
		backgroundColor: (context: any) => colors.getProjectStatusColor(
			context,
			projects_completed,
			project_sent_date,
			project_resend,
			project_blocked_until,
			project_retries,
			projects_count,
		),
		icon: [...Array(projects_count)].map((_x, i) => {
			return projects_completed >= i + 1
				? styles.iconGreen
				: (project_sent_date !== null)
					? styles.iconYellow
					: (project_resend === true)
						? styles.iconOrange
						: (project_blocked_until !== null || project_retries > 0)
							? styles.iconRed
							: styles.iconGray;
		}),
	};

	return (
		<div className="flex flex-1 items-center">
			{
				theories_count > 0 && (
					<a aria-label="vai al corso" href={`${guidePath}#course`} className={`relative m-2.5 size-14${!guidePath ? "pointer-events-none" : ""}`}>
						<Doughnut
							data={
								{
									labels: ["Teoria"],
									datasets: [
										{
											data: theory_progress > 0 ? [theory_progress, 100 - theory_progress] : [100],
											backgroundColor: (context): any => (theory_progress > 0 ?
												[colors.createGradient(context), colors.progressColors.inactiveBackgroundColor]
												: colors.progressColors.inactiveBackgroundColor),
											hoverBorderColor: ["transparent"],
										},
									],
								}
							}
							options={doughnutSetting}
							width={56}
						/>
						<div>
							<FaBook className={`${styles.chartIcon} ${theory_progress === 100 ? styles.iconGreen : styles.iconGray}`} />
						</div>
					</a>
				)
			}
			{
				tests_count > 0 && (
					<a aria-label="vai al test" href={`${guidePath}#test`} className={`relative m-2.5 size-14${!guidePath ? "pointer-events-none" : ""}`}>
						<Doughnut
							data={
								{
									datasets: [
										{
											data: [100],
											backgroundColor: (context) => (test_points > 0
												? colors.createGradient(context)
												: (test_retries > 0 || test_blocked_until !== null)
													? colors.progressColors.blockedBackgroundColor
													: colors.progressColors.inactiveBackgroundColor),
											hoverBorderColor: ["transparent"],
										},
									],
								}
							}
							options={doughnutSetting}
							width={56}
						/>
						<div>
							<FaTasks className={`${styles.chartIcon} ${test_points > 0 ? styles.iconGreen : (test_retries > 0 || test_blocked_until !== null) ? styles.iconRed : styles.iconGray}`} />
						</div>
					</a>
				)
			}
			{
				projects_count > 0 && (
					<a aria-label="vai al progetto" href={`${guidePath}#project_to_show`} className={`relative m-2.5 size-14${!guidePath ? "pointer-events-none" : ""}`}>
						<Doughnut
							data={
								{
									datasets: [
										{
											data: projectsDataset.data,
											backgroundColor: projectsDataset.backgroundColor,
											hoverBorderColor: ["transparent"],
										},
									],
								}
							}
							options={doughnutSetting}
							width={56}
						/>
						<div>
							<FaFileInvoice className={`${styles.chartIcon} ${projectsDataset.icon[projectsDataset.icon.length - 1]}`} />
						</div>
					</a>
				)
			}
		</div>
	);
};

export default GuideCircularProgress;
