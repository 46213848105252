
import { Helmet } from "react-helmet";

import { Button, Typography } from "design2impact";

import config from "config";


const Error404 = () => {
	return (
		<>
			<Helmet>
				<title>Pagina non trovata | start2impact</title>
			</Helmet>

			<div
				className="flex min-h-screen flex-col items-center justify-center bg-brand-primary-light text-center"
			>
				<img src={`${config.assetsPath}images/404.svg`} alt="404" className="block h-auto max-w-full" />
				<Typography variant="h1" className="sr-only size-0">404 - Pagina non disponibile</Typography>

				<Typography variant="h1" className="mt-14 text-brand-primary">Ops! Pagina non disponibile</Typography>
				<Typography variant="label-1" className="mx-0 mb-0 mt-3 text-greyscale-secondary">Scrivi in chat cosa stavi cercando e ti aiuteremo.</Typography>

				<Button to={{ pathname: `${config.basepath}` }} className="mt-8" as="internal-link">Torna alla home</Button>
			</div>
		</>
	);
};

export default Error404;
