

import { Tag, Typography } from "design2impact";

import { Container } from "types/defs";


interface RequirementsProps {
	requirements: Container["details"][number]["requirements"];
	isFinalProject: boolean
}

function Requirements({ requirements, isFinalProject }: RequirementsProps) {
	return (
		<div>
			<Typography variant="paragraph-span3" className="mb-2 text-greyscale-secondary">Prerequisiti Necessari</Typography>

			<div>
				{isFinalProject ? (
					<Tag label="Tutti i Corsi obbligatori inclusi nel Master" color="#4D4D4D" />
				) : (
					requirements.length > 0 &&
					requirements.map((requirement: any) => {
						return (
							<a
								key={requirement.id}
								href={`/corsi/${requirement.slug}`}
								style={{ textDecoration: "none", color: "inherit" }}
							>
								<Tag color={requirement.topic.color} label={requirement.title} />
							</a>
						);
					})
				)}
			</div>
		</div>
	);
}

export default Requirements;
