import { useState } from "react";

import { Typography } from "design2impact";

import OnuObjectivesModal from "features/student-home/card-student.component/modal-onu-objectives";
import WorkingSectorModal from "features/student-home/card-student.component/modal-working-sectors";
import EditableCard from "./editable-card";
import { FaRegEdit } from "react-icons/fa";

import { FetchStudentResponse } from "types/api";
import { OnuObjective, WorkingSector } from "types/defs";
import config from "config";

interface CardStudentProps {
	student: FetchStudentResponse
	workingSectors: WorkingSector[]
	onuObjectives: OnuObjective[]
	userId: string
}

const CardStudent = ({ student, workingSectors, onuObjectives, userId }: CardStudentProps) => {
	const hasSelectedWorkingSector = student.user.working_sectors.length > 0;
	const hasSelectedOnuObjectives = student.user.onu_objectives.length > 0;
	const studentProgress = student.progress > 0 ? student.progress : 0;

	const [isWorkingSectorsModalOpen, setIsWorkingSectorsModalOpen] = useState(false);
	const [isOnuObjectivesModalOpen, setIsOnuObjectivesModalOpen] = useState(false);


	return (
		<div className="rounded-lg bg-greyscale-base p-6 shadow">
			<div className="mt-6 grid grid-cols-12 gap-y-6 lg:gap-8">
				<div className="col-span-12 lg:col-span-8">
					<EditableCard
						className="pl-0"
						title="Il Master che hai scelto &egrave;:"
						button={(
							<a
								aria-label="Modifica il master scelto"
								className="inline-flex items-center"
								href={`${config.basepath}master`}
							>
								<FaRegEdit size={16} />
							</a>
						)}
						body={(
							<Typography variant="h5">
								{student.master.title}
							</Typography>
						)}
					/>
				</div>
				<div className="col-span-12 lg:col-span-4">
					<EditableCard
						className="flex h-full flex-1 flex-col justify-between pr-0"
						title="Percorso completo al:"
						body={(
							<>
								<div className="relative mr-6 h-[24px] w-full overflow-hidden rounded-md bg-greyscale-base">
									<span
										style={{
											width: `${studentProgress}%`,
											background: "linear-gradient(45.72deg, #007369 0%, #08F7A1 100%)",
										}}
										className="absolute h-[24px] rounded-md"
									/>
								</div>
								<Typography variant="label-2" className="font-semibold">
									{studentProgress}
									%
								</Typography>
							</>
						)}
					/>
				</div>
			</div>
			<div className="mt-6 grid grid-cols-12 gap-y-6 lg:gap-8">
				<div className="col-span-12 lg:col-span-8">
					<EditableCard
						className="pb-0 pl-0"
						title="I miei Settori di interesse:"
						button={<WorkingSectorModal sectorsList={workingSectors} userId={userId} modal={isWorkingSectorsModalOpen} setModalFn={setIsWorkingSectorsModalOpen} />}
						body={(
							<div
								className={`flex w-full items-center overflow-auto ${!hasSelectedWorkingSector && "justify-between"}`}
							>
								{hasSelectedWorkingSector
									? student.user.working_sectors.map((sector, index) => (
										<button type="button" key={`sector_${sector.id}_${index}`} className="m-2 flex size-20 shrink-0 items-center justify-center rounded" onClick={() => setIsWorkingSectorsModalOpen(true)}>
											<img
												src={`https://res.cloudinary.com/start2impact/image/upload/v1667204106/Piattaforma/working_sectors/${sector.filename}`}
												alt={`Icona settore lavorativo ${sector.title}`}
												className="mr-0 text-[28px] text-[#5ec868]"
												height="80"
												width="80"
											/>
										</button>
									))
									: Array.from({ length: 6 }).map((item, index) => (
										<button
											aria-label="Seleziona i tuoi settori d'interesse"
											type="button"
											key={`sector_${item}_${index}`}
											onClick={() => setIsWorkingSectorsModalOpen(true)}
											className="m-2 flex size-20 shrink-0 items-center justify-center rounded border-2 border-dashed border-brand-primary"
										/>
									))}
							</div>
						)}
					/>
				</div>
				<div className="col-span-12 lg:col-span-4">
					<EditableCard
						className="pb-0 pr-0"
						title="I miei Obiettivi ONU:"
						button={<OnuObjectivesModal onuObjectivesList={onuObjectives} userId={userId} modal={isOnuObjectivesModalOpen} setModalFn={setIsOnuObjectivesModalOpen} />}
						body={(
							<div className="w-full">
								<div
									className={`flex w-full items-center overflow-auto ${!hasSelectedOnuObjectives && "justify-between"}`}
								>
									{hasSelectedOnuObjectives
										? student.user.onu_objectives.map((objective, index) => {
											return (
												<button
													type="button"
													key={`onu_objective_${objective.id}_${index}`}
													onClick={() => setIsOnuObjectivesModalOpen(true)}
													className="m-2 flex size-20 shrink-0 items-center justify-center rounded"
												>
													<img
														width="80"
														height="80"
														src={`${config.cloudinaryFetchPath}${objective.pic_url}`}
														alt={objective.title}
													/>
												</button>
											);
										})
										: Array.from({ length: 3 }).map((item, index) => (
											<button
												aria-label="Seleziona gli obiettivi ONU di tuo interesse"
												type="button"
												key={`onu_objective_${item}_${index}`}
												onClick={() => setIsOnuObjectivesModalOpen(true)}
												className="m-2 flex size-20 shrink-0 items-center justify-center rounded border-2 border-dashed border-brand-primary"
											/>
										))}
								</div>
							</div>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default CardStudent;
