

import { Typography } from "design2impact";

import { CardCourseStatic } from "components/card-course";
import { FaAward, FaBookmark, FaRandom } from "react-icons/fa";

import { Master } from "types/defs";
import config from "config";

interface CourseCardListProps {
	containers: Master["containers"]
}

const CourseCardList = ({ containers }: CourseCardListProps) => {
	const iconType = (type: any) => {
		switch (type) {
			case "Corso Opzionale":
				return <FaBookmark className="mr-2 size-5" />;
			case "Scegli uno o più Corsi tra:":
				return <FaRandom className="mr-2 size-5" />;
			case "Progetto Finale":
				return <FaAward className="mr-2 size-5" />;
			default:
				return null;
		}
	};

	return (
		containers.map((container) => {
			const doesContainLabel = container.type.id !== "7f2f2cd0-7121-4038-89e8-6b15546c6cd3"; // a scelta tra, progetto finale, opzionale
			const isFinalProject = container.type.id === "1140639d-bbc7-47c7-9477-a2218daf02f2";
			const cardLabel = container.type.label.toLowerCase() === "opzionale" ? "Corso Opzionale" : container.type.label;
			const isIntro = container.details[0].course.is_intro;
			const displayLabel = (doesContainLabel || isFinalProject) && !isIntro;
			return (
				<div
					key={`home-course-${container.id}`}
					className={`mb-4 rounded-lg shadow ${displayLabel ? "border-2 border-brand-primary-light px-4" : ""} `}
				>
					<Typography variant="label-2" className={`${displayLabel ? "flex items-center py-4 text-greyscale-secondary" : "hidden"}`}>
						{iconType(cardLabel)}
						{" "}
						{cardLabel}
					</Typography>
					{container.details.filter(detail => detail.course.is_intro === false).map((pathGuide, i) => {
						if (pathGuide?.course) {
							const pic_url = pathGuide?.course?.image?.url
								? `${config.cloudinaryFetchPath}${pathGuide?.course?.image?.url}`
								: "https://via.placeholder.com/150";
							return (
								<div key={container.id + i} className={`rounded-lg border-2 border-brand-primary-light ${displayLabel ? "mb-4" : ""}`}>
									<CardCourseStatic
										className="shadow-none"
										hasTag
										course={pathGuide?.course}
										pic_url={pic_url}
									/>
								</div>
							);
						}
						return null;
					})}
				</div>
			);
		})
	);
};

export default CourseCardList;
