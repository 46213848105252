import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "context/auth-context";

import { Modal, Typography } from "design2impact";

import { IoClose } from "react-icons/io5";

import { setBadgeAsSeen } from "../../api/mutations";
import { fetchBadges } from "../../api/queries";

import { Badge } from "types/defs";


const PortalBadge = () => {
	const { session } = useAuth();
	const [isOpenBadgeModal, setIsOpenBadgeModal] = useState(false);
	const [showedBadge, setShowedBadge] = useState<Badge | null>(null);

	const { data: badges, refetch } = useQuery({
		queryKey: ["badges", session?.user.id],
		queryFn: () => fetchBadges(session?.user.id || ""),
		enabled: !!session?.user.id,
	});

	const { mutate: setBadgeAsSeenMutation } = useMutation({
		mutationKey: ["badges", session?.user.id],
		mutationFn: setBadgeAsSeen,
		onSuccess: () => refetch(),
	});

	const onClosePopupBadge = (selectedBadge: { title: string, description: string, picUrl: string, confirmButtonCta: string }) => {
		setIsOpenBadgeModal(false);
		setShowedBadge(null);
		setBadgeAsSeenMutation(selectedBadge.confirmButtonCta);
	};

	useEffect(() => {
		if (badges?.length > 0) {
			setShowedBadge(badges[badges.length - 1]);
			setIsOpenBadgeModal(true);
		}
	}, [badges]);


	return (
		isOpenBadgeModal && showedBadge && createPortal(
			<Modal hasCloseButton={false} isVisible={isOpenBadgeModal} toggleModalFn={() => onClosePopupBadge(showedBadge)} className="relative !max-w-[480px] overflow-hidden rounded-lg px-14 pb-14 text-center">
				<div>
					<button aria-label="Chiudi Popup" type="button" onClick={() => onClosePopupBadge(showedBadge)} className="absolute right-4 top-4 border-[none] p-0">
						<IoClose size={32} color="#FF5A5F" />
					</button>

					<div className="m-auto flex size-[120px] items-center">
						<img
							src={showedBadge.picUrl || "https://via.placeholder.com/150"}
							width={120}
							height={120}
							alt={`Immagine del badge ${showedBadge.title}`}
						/>
					</div>

					<Typography variant="h2" className="mx-0 mb-4 mt-6 text-greyscale-primary">{showedBadge.title}</Typography>
					{showedBadge.description && <span className="block text-paragraph-span1-mobile text-greyscale-secondary sm:text-paragraph-span1-desktop" dangerouslySetInnerHTML={{ __html: showedBadge.description }} />}
				</div>
			</Modal>,
			document.getElementById("badges")!,
		)
	);
};

export default PortalBadge;
