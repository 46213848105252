import { useCustomerly } from "react-live-chat-customerly";
import { Outlet, useLocation } from "react-router-dom";
import useAuth from "context/auth-context";
import useViewport from "helpers/useViewport";

import Sidebar from "@start2impact/sidebar";
import { Footer, MobileToggleIcon } from "design2impact";

import Logo from "components/logo";

import config from "config";

interface LayoutSidebarProps {
	hideFooter?: boolean;
}

const LayoutSidebar = ({ hideFooter = false }: LayoutSidebarProps) => {
	const { session, user } = useAuth();
	const { open } = useCustomerly();

	const { pathname } = useLocation();
	const { width } = useViewport();

	if (!session) return null;

	return (
		<>
			<Sidebar
				LogoComponent={Logo}
				pathname={pathname}
				menuItems={config.menuItems(session?.user?.plan_slug, session?.user?.role)}
				userSession={session}
				userRecord={user}
				width={width}
				basepath={config.assetsPath}
				disImpersonateCB={() => {
					window.location.href = `${config.basepath}admin/users/stop_impersonating`;
				}}
			/>
			{pathname !== "/" && (
				<div className="[@media(min-width:1200px)]:hidden">
					<div className="container flex justify-end bg-ui-secondary pt-6">
						<MobileToggleIcon />
					</div>
				</div>
			)}

			<Outlet context={{ userSession: session?.user }} />
			{!hideFooter && <Footer openCustomerlyLiveChat={config.isProduction ? () => open() : undefined} />}
		</>

	);
};

export default LayoutSidebar;
