import { useState } from "react";
import { Description } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button, Modal, Typography } from "design2impact";

import { FaRegEdit } from "react-icons/fa";

import { updateOnuObjectives } from "api/mutations";

import { FetchStudentResponse } from "types/api";
import { OnuObjective } from "types/defs";
import config from "config";

interface OnuObjectiveSelectable extends OnuObjective {
	is_selected?: boolean
}

interface OnuObjectiveModalProps {
	onuObjectivesList: OnuObjectiveSelectable[]
	userId: string
	modal: boolean
	setModalFn: (value: boolean) => void
}

const countSelected = (listObjectives: OnuObjectiveSelectable[]) => listObjectives.filter((obj) => obj.is_selected === true).length;

const OnuObjectivesModal = ({ onuObjectivesList, userId, modal, setModalFn }: OnuObjectiveModalProps) => {
	const queryClient = useQueryClient();

	const [newListOnuObjectives, setnewListOnuObjectives] = useState(onuObjectivesList);
	const [selectedOnuObjectivesCounter, setSelectedOnuObjectivesCounter] = useState(countSelected(newListOnuObjectives));

	// TODO add error handler
	const { mutate: updateOnuObjectivesMutation } = useMutation({
		mutationKey: ["onuObjectives", userId],
		mutationFn: updateOnuObjectives,
		onSuccess: (data) => {
			queryClient.setQueryData(["studentData", userId], (oldData: FetchStudentResponse) => ({
				...oldData,
				user: {
					...oldData.user,
					onu_objectives: data,
				},
			}));
			setModalFn(!modal);
		},
	});

	const toggleArrayItem = (arr: OnuObjectiveSelectable[], item: OnuObjectiveSelectable) => {
		const itemIdx = arr.findIndex((el) => el.id === item.id);
		typeof arr[itemIdx].is_selected === "undefined"
			? arr[itemIdx] = ({ ...arr[itemIdx], is_selected: true })
			: arr[itemIdx].is_selected = !arr[itemIdx].is_selected;
		return arr;
	};

	const refreshListOnuObjectives = (item: OnuObjectiveSelectable) => {
		if (item.is_selected || (!item.is_selected && selectedOnuObjectivesCounter < 3)) {
			setnewListOnuObjectives(toggleArrayItem(newListOnuObjectives, item));
			setSelectedOnuObjectivesCounter(countSelected(newListOnuObjectives));
		}
		return false;
	};

	return (
		<>
			<button aria-label="Chiudi il modale obiettivi ONU" type="button" onClick={() => setModalFn(!modal)}>
				<FaRegEdit className="size-4" />
			</button>
			<Modal isVisible={modal} toggleModalFn={setModalFn} title="A quali grandi sfide della nostra epoca vorresti contribuire?">
				<div className="mt-2">
					<Description className="text-paragraph-span2-mobile text-greyscale-primary sm:text-paragraph-span2-desktop">
						Scegli fino a 3 Obiettivi di Sviluppo Sostenibile delle Nazioni Unite. Essere
						consapevoli delle cause che abbiamo a cuore ci può aiutare a scegliere aziende in cui
						sentirci realizzati con il nostro lavoro.
					</Description>

					{selectedOnuObjectivesCounter >= 3 && (
						<div className="my-4">
							<Typography
								variant="paragraph-span2"
								className="rounded-lg border border-[#ffecb5] bg-[#fff3cd] p-4 text-[#664d03]"
								role="alert"
							>
								Hai raggiunto il numero massimo di 3 obiettivi selezionabili. Per cambiare scelta,
								deseleziona un obiettivo.
							</Typography>
						</div>
					)}
					<div className="grid grid-cols-2 gap-4 pt-8 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6">
						{newListOnuObjectives.map((item) => {
							return (
								<div
									key={item.id}
									className={`mx-auto flex size-[116px] shrink-0 cursor-pointer items-center justify-center rounded-[10px] border-[3px] border-brand-primary-light p-1 ${item.is_selected === true && "!border-brand-base"}`}
									onClick={() => refreshListOnuObjectives(item)}
									aria-hidden="true"
								>
									<div className="flex flex-col overflow-hidden rounded-[8px]">
										<img
											className="size-[100px]"
											alt={item.title}
											src={
												item.pic_url
													? `${config.cloudinaryFetchPath}${item?.pic_url}`
													: "https://via.placeholder.com/100"
											}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="mt-4 flex w-full px-8">
					<Button
						as="button"
						className="mx-auto"
						onClick={() => updateOnuObjectivesMutation({ userId, list: newListOnuObjectives })}
					>
						SALVA MODIFICHE
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default OnuObjectivesModal;
