import dayjs from "dayjs";

import { colors } from "helpers2impact";

import { Nullable } from "../../types/utils";


export const getTestDetails = (
	theory_progress: number,
	test_points: number,
	test_retries: number,
	test_max_points: number,
	test_blocked_until: Nullable<string>,
) => {
	let textCopy: string = "null";
	let isButtonActive = true;
	let iconCssBackground = null;

	let circleBackgroundColor = (_context?: any) => colors.progressColors.inactiveBackgroundColor;
	const backendTestBlockAfterNRetries = 14;

	const hoursBlocked = dayjs(test_blocked_until).diff(new Date(), "hour");
	const minutesBlocked = dayjs(test_blocked_until).diff(new Date(), "minute");

	let timeBlockedLabel = "";

	if (hoursBlocked >= 24) {
		timeBlockedLabel = `tra ${Math.round(hoursBlocked / 24)} ${Math.round(hoursBlocked / 24) === 1 ? "giorno" : "giorni"}`;
	}
	else if (hoursBlocked >= 1) {
		timeBlockedLabel = `tra ${hoursBlocked} ${hoursBlocked === 1 ? "ora" : "ore"}`;
	}
	else if (minutesBlocked > 0 && minutesBlocked < 60) {
		timeBlockedLabel = "a breve";
	}

	if (test_points > 0) { // test superato: Test - 270/max
		textCopy = `Test - ${test_points}/${test_max_points}`;
		iconCssBackground = "fill-[url(#lgrad)]";
		circleBackgroundColor = (context: any) => colors.progressColors.activeBackgroundColor(context);
		isButtonActive = false;
	}
	else if (timeBlockedLabel) { // Test bloccato
		textCopy = `Test non superato<br />Potrai riprovare ${timeBlockedLabel}`;
		iconCssBackground = "text-feedback-error";
		circleBackgroundColor = () => colors.progressColors.blockedBackgroundColor;
		isButtonActive = false;
	}
	else if (test_blocked_until !== null && hoursBlocked <= 0) { // Test sbloccato dopo periodo di blocco
		textCopy = "Test non superato<br />Riprova!";
		iconCssBackground = "text-feedback-error";
		circleBackgroundColor = () => colors.progressColors.blockedBackgroundColor;
		isButtonActive = true;
	}
	else if (test_retries === 2) { // Test non superato, 1 solo tentativo rimanente
		textCopy = "Test non superato<br />1 tentativo rimanente";
		iconCssBackground = "text-feedback-error";
		circleBackgroundColor = () => colors.progressColors.blockedBackgroundColor;
		isButtonActive = true;
	}
	else if (test_retries === 1) { // Test non superato, N tentativi rimanenti
		textCopy = "Test non superato<br />2 tentativi rimanenti";
		iconCssBackground = "text-feedback-error";
		circleBackgroundColor = () => colors.progressColors.blockedBackgroundColor;
		isButtonActive = true;
	}
	else if (theory_progress < 100) { // Teoria NON completata
		textCopy = "Il test sarà disponibile una volta completata la teoria";
		iconCssBackground = "text-[#777]";
		circleBackgroundColor = () => colors.progressColors.inactiveBackgroundColor;
		isButtonActive = false;
	}
	else if (theory_progress === 100 && test_retries === 0) {
		textCopy = "Test da completare"; // Teoria completata, test NON svolto
		iconCssBackground = "text-[#777]";
		circleBackgroundColor = () => colors.progressColors.inactiveBackgroundColor;
		isButtonActive = true;
	}
	else {
		textCopy = "Questo non era previsto! Ce lo segnali? :)";
	} // bug
	return {
		text: textCopy,
		button: isButtonActive,
		colors: {
			iconCssBackground,
			circleBackgroundColor,
		},
		backendTestBlockAfterNRetries,
	};
};
