import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "context/auth-context";
import Cookies from "js-cookie";

import { Checkbox, Modal } from "design2impact";
import { date } from "helpers2impact";

import VideoPlayer from "components/video-player";

import { neverShowWelcomeVideo } from "api/mutations";
import { fetchStudentData } from "api/queries";


const isSameDayLastSeen = date.isSameDay(Cookies.get("welcome_video_last_seen") || "", new Date().toDateString());

const ModalWelcomeVideo = () => {
	const { session } = useAuth();

	const { data: studentData } = useQuery({
		queryKey: ["studentData", session?.user.id],
		queryFn: () => fetchStudentData(session?.user?.id),
		enabled: !!session?.user?.id,
		retry: false,
	});

	const [showModal, setShowModal] = useState(false);
	const [neverShowAgain, setNeverShowAgain] = useState(false);

	const { mutate: neverShowWelcomeVideoMutation } = useMutation({
		mutationKey: ["welcomeVideo", session?.user?.id],
		mutationFn: neverShowWelcomeVideo,
	});

	const neverShowAgainHandler = () => {
		Cookies.remove("welcome_video_last_seen");
		neverShowWelcomeVideoMutation(session?.user?.id);
		setShowModal(false);
	};

	const handleLastVideoSeen = (date: string) => {
		Cookies.set("welcome_video_last_seen", date);
	};

	const toggleModal = () => {
		if (neverShowAgain) {
			neverShowAgainHandler();
		}
		else {
			handleLastVideoSeen(new Date().toDateString());
		}
		setShowModal(false);
	};


	useEffect(() => {
		// Check if student exists then check if student never seen welcome video
		if (studentData?.user?.onboarding_video_showed === 0 && !isSameDayLastSeen && (session?.user?.email !== "alessandrolorrai@gmail.com")) {
			setShowModal(true);
		}
	}, [studentData, session?.user?.email]);


	return (
		<Modal isVisible={showModal} toggleModalFn={toggleModal} className="max-w-7xl">
			<VideoPlayer
				playsInline
				poster="https://res.cloudinary.com/start2impact/image/upload/v1662486172/video/video_poster_yh6igb.jpg"
				src="https://assets.start2impact.it/video_onboarding_07092022.mp4"
				onVideoStart={() => handleLastVideoSeen(new Date().toDateString())}
				onVideoEnd={neverShowAgainHandler}
			/>
			<div className="mt-4 flex justify-center">
				<Checkbox
					id="welcome-video"
					name="welcome-video"
					onChange={(e) => setNeverShowAgain(e.currentTarget.checked)}
					checked={neverShowAgain}
					label="Non mostrare più"
				/>
			</div>
		</Modal>
	);
};


export default ModalWelcomeVideo;
