import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useAuth from "context/auth-context";
import Cookies from "js-cookie";

import { Typography } from "design2impact";
import { date } from "helpers2impact";

import { FaInfo, FaTimes } from "react-icons/fa";

import { fetchRenewalData, fetchSubscription } from "api/queries";

import config from "config";


const isSameDayLastSeen = date.isSameDay(Cookies.get("renewal_alert_last_seen") || "", new Date().toDateString());

const PortalRenewalAlert = () => {
	const { session } = useAuth();

	const [showAlert, setShowAlert] = useState(false);

	const { isLoading: subscriptionLoading, error: subscriptionError } = useQuery<any, AxiosError>({
		queryKey: ["subscription", session?.user.id],
		queryFn: () => fetchSubscription(session!.user.id),
		enabled: !!session?.user.id,
		retry: 3,
	});

	const { data: renewalData } = useQuery({
		queryKey: ["renewalData", session?.user.id],
		queryFn: () => fetchRenewalData(session!.user.id),
		enabled: !!session?.user.id && !subscriptionError && !subscriptionLoading,
	});

	const onCloseAlert = () => {
		Cookies.set("renewal_alert_last_seen", new Date().toDateString());
		setShowAlert(false);
	};

	useEffect(() => {
		if (renewalData?.display_banner) {
			// if date exists check if there's renewal seen cookie
			const renewalLastSeenDate = Cookies.get("renewal_alert_last_seen");

			// show the Alert only if doesn't exist or if exist and date is less than today
			if (!renewalLastSeenDate || !isSameDayLastSeen) {
				setShowAlert(true);
			}
		}
	}, [renewalData]);

	return (
		showAlert && createPortal(
			<div className="container mt-10 flex">
				<div className="relative flex w-full items-center rounded rounded-r-lg bg-greyscale-base shadow">
					<button aria-label="Chiudi badge" onClick={onCloseAlert} type="button" className="absolute right-4 top-2.5 text-greyscale-secondary">
						<FaTimes size={16} />
					</button>
					<div className="h-full w-1.5 shrink-0 rounded-l-lg bg-feedback-error" />
					<div className="m-6 flex items-center">
						<div className="mr-6 flex size-8 shrink-0 items-center justify-center rounded-full bg-feedback-error text-greyscale-base">
							<FaInfo />
						</div>
						<Typography variant="paragraph-span1">
							Il tuo piano scadrà il
							{" "}
							{new Date(renewalData?.subscription?.expiration_date)?.toLocaleDateString()}
							.
							Per continuare a entrare in piattaforma e rinnovare il tuo abbonamento automaticamente a 299€
							{" "}
							<a className="text-[blue] underline" href={`${config.basepath}subscribe/index`}>conferma i tuoi dati di pagamento qui</a>
							.
						</Typography>
					</div>
				</div>
			</div>,
			document.getElementById("alerts")!,
		)
	);
};

export default PortalRenewalAlert;
