import React from "react";
import clsx from "clsx";

import { Typography } from "design2impact";

import { Topic } from "types/defs";

interface TopicSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
	topics: Omit<Topic, "masters">[]
	onSelectTopicFn: (value: string) => void
	selectedTopicId: string | null
	suggestedField?: boolean
}

const TopicSelector = ({ className, topics, onSelectTopicFn, selectedTopicId, suggestedField = false, ...restProps }: TopicSelectorProps) => {
	return (
		<div className={clsx("flex flex-row gap-x-4 overflow-auto py-4 md:justify-center", className)} {...restProps}>
			<a
				href="#tutti-gli-argomenti"
				onClick={() => onSelectTopicFn("")}
				className="shrink-0 cursor-pointer hover:text-greyscale-primary"
			>
				<Typography
					variant="label-2"
					key="all_topic"
					className={`block cursor-pointer rounded-lg border border-greyscale-primary bg-greyscale-base px-4 py-2 ${!selectedTopicId && "!border-brand-base !bg-brand-base"}`}
				>
					Tutti
				</Typography>
			</a>
			{suggestedField && (
				<a
					href="#consigliati"
					onClick={() => onSelectTopicFn("suggested")}
					className="shrink-0 cursor-pointer hover:text-greyscale-primary"
				>
					<Typography
						variant="label-2"
						key="suggested"
						className={`block cursor-pointer rounded-lg border border-greyscale-primary bg-greyscale-base px-4 py-2 ${selectedTopicId === "suggested" && "!border-brand-base !bg-brand-base"}`}
					>
						Consigliati
					</Typography>
				</a>
			)}
			{topics.length > 0 &&
				topics.map((topic) => {
					return (
						<a
							className="shrink-0 cursor-pointer hover:text-greyscale-primary"
							key={`topic_${topic.id}`}
							href={`#argomento-${topic.name?.toLowerCase().split(" ").join("-")}`}
							onClick={() => onSelectTopicFn(topic.id)}
						>
							<Typography
								variant="label-2"
								className={`block cursor-pointer rounded-lg border border-greyscale-primary bg-greyscale-base px-4 py-2 ${selectedTopicId === topic.id && "!border-brand-base !bg-brand-base"}`}
							>
								{topic.name}
							</Typography>
						</a>
					);
				})}
		</div>
	);
};

export default TopicSelector;
