

import { Typography } from "design2impact";

import { BsListCheck } from "react-icons/bs";
import { FaFileInvoice, FaMedal, FaRegClock } from "react-icons/fa";

interface CardCourseStatsProps {
	studentCompletedGuide: boolean,
	duration_guide: number
	duration_project: number
	max_points_guide: number
	test_points: number
	test_max_points: number
	projects_count: number
	projects_completed: number
	projects_points: null | string,
	tests_count: number
}


const CardCourseStats = ({
	studentCompletedGuide = false,
	duration_guide = 0,
	duration_project = 0,
	max_points_guide = 0,
	test_points = 0,
	test_max_points = 0,
	projects_count = 0,
	projects_completed = 0,
	projects_points = "",
	tests_count = 0,
}: CardCourseStatsProps) => {
	if (!studentCompletedGuide || projects_completed < projects_count) {
		return (
			<ul className="mt-4 block md:mt-0 md:flex md:flex-wrap md:gap-y-1">
				{
					duration_guide > 0 && (
						<li className="mr-4 flex items-center">
							<FaRegClock size={20} className="mr-2 text-greyscale-secondary" />
							<Typography variant="label-2">
								{" "}
								{duration_guide}
								{" "}
								Ore di Teoria
							</Typography>
						</li>
					)
				}
				{
					duration_project > 0 && (
						<li className="mr-4 mt-1 flex items-center md:mt-0">
							<FaRegClock size={20} className="mr-2 text-greyscale-secondary" />
							<Typography variant="label-2">
								{" "}
								{duration_project}
								{" "}
								Ore di Pratica
							</Typography>
						</li>
					)
				}
				{tests_count > 0 && (
					<li className="mt-1 flex items-center md:mt-0">
						<FaMedal size={20} className="mr-2 text-greyscale-secondary" />
						<Typography variant="label-2">
							{" "}
							{max_points_guide}
							{" "}
							pt Max
						</Typography>
					</li>
				)}
			</ul>
		);
	}

	const projectsLabel = projects_count > 1 ? "Progetti" : "Progetto";
	const projectsPoints = projects_count > 1
		?
		`${projects_points?.split(",").map(project => project).reduce((a, b) => {
			const total = +a.split(":")[0] + +b.split(":")[0];
			return total.toString();
		})}/${projects_points?.split(",").map(project => project).reduce((a, b) => {
			const total = +a.split(":")[1] + +b.split(":")[1];
			return total.toString();
		})}`
		:
		`${projects_points?.split(":")[0]}/${projects_points?.split(":")[1]}`;

	return (
		<ul className="mt-4 block md:mt-0 md:flex md:flex-wrap md:gap-y-1">
			{
				test_points > 0 && (
					<li className="mr-4 flex items-center">
						<BsListCheck size={20} className="mr-2 text-greyscale-secondary" />
						<Typography variant="label-2">
							{` Test: ${test_points}/${test_max_points}`}
						</Typography>
					</li>
				)
			}
			{
				projects_points !== null && (
					<li className="mt-1 flex items-center md:mt-0">
						<FaFileInvoice size={20} className="mr-2 text-greyscale-secondary" />
						<Typography variant="label-2">
							{" "}
							{projectsLabel}
							:
							{" "}
							{projectsPoints}
						</Typography>
					</li>
				)
			}
		</ul>
	);
};


export default CardCourseStats;
