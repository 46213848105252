
import Cookies from "js-cookie";

import { Button, Typography } from "design2impact";

import { Master } from "types/defs";


const setCookieForSelectedMaster = (masterId: string) => {
	Cookies.set("selected_master", masterId, { domain: ".start2impact.it" });
};

interface CardMasterProps {
	master: Master
	openModalMasterDetails: (master: Master) => void
}

const CardMaster = ({ openModalMasterDetails, master }: CardMasterProps) => {
	const { id, title, subinfo, courses_count, projects_count, description, topic } = master;

	return (
		<div className="relative mx-auto h-full w-[350px] overflow-hidden rounded-lg shadow">

			{subinfo && (
				<div className="absolute left-4 top-4 rounded bg-greyscale-base px-2 py-1">
					<span
						className="block text-label-3-mobile font-medium sm:text-label-3-desktop"
						style={{ color: topic?.color || "black" }}
						dangerouslySetInnerHTML={{ __html: subinfo }}
					/>
				</div>
			)}

			<div className="h-[180px] max-h-[180px] w-full overflow-hidden">
				<img
					className="object-cover"
					alt={`Copertina del master ${title}`}
					src={`https://res.cloudinary.com/start2impact/image/upload/v1679053979/Piattaforma/masters/${id}.webp`}
					width="350"
					height="180"
				/>
			</div>

			<div className="flex flex-col p-6 text-left">
				<Typography variant="h2" className="mb-4" style={{ color: topic?.color || "black" }}>
					{title}
				</Typography>
				<div className="mb-4 flex">
					<div className="mr-4 flex items-baseline">
						<Typography variant="h4" className="mr-2">{courses_count}</Typography>
						<Typography variant="paragraph-span1">Corsi</Typography>
					</div>
					<div className="flex items-baseline">
						<Typography variant="h4" className="mr-2">{projects_count}</Typography>
						<Typography variant="paragraph-span1">Progetti</Typography>
					</div>
				</div>

				<span
					className="text-paragraph-span3-mobile sm:text-paragraph-span3-desktop"
					dangerouslySetInnerHTML={{ __html: description }}
				/>

				<div className="mt-auto">
					<hr className="my-4 h-0.5 text-pine-100" />
					<div className="flex justify-end">
						<Button
							as="button"
							variant="secondary"
							size="md"
							className="mr-4"
							onClick={() => {
								setCookieForSelectedMaster(master.id);
								openModalMasterDetails(master);
							}}
						>
							Scopri di più
						</Button>
						<Button
							size="md"
							href="/checkout?plan=m1-o27"
							onClick={() => setCookieForSelectedMaster(id)}
							as="external-link"
						>
							Inizia
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardMaster;
