

import { Tag, Typography } from "design2impact";

import { Course } from "types/defs";


function Skills({ skills }: { skills: Course["skills"] }) {
	return (
		<div>
			<Typography variant="paragraph-span3" className="mb-2 text-greyscale-secondary">Skill Principali</Typography>
			<div className="flex flex-wrap gap-y-1 md:w-1/2">
				{skills && skills.length > 0 &&
					skills.map((skill, index) => (
						<Tag
							key={skill.id + index}
							color="#4D4D4D"
							label={skill.label}
							className="cursor-default"
						/>
					))}
			</div>
		</div>
	);
}

export default Skills;
