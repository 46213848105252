import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import Cookies from "js-cookie";

import { Loader } from "design2impact";
import { checkCookie } from "helpers2impact";

import analytics from "../helpers/google-analytics";

import { hadAccess } from "../api/mutations/index";
import { fetchSubscription, fetchUser } from "../api/queries";

import { FetchUserResponse } from "types/api";
import { Session } from "types/defs";
import config from "../config";

interface AuthProps {
	children: ReactNode
}

type ContextType = { session: Session | null | undefined, user: FetchUserResponse | null | undefined }
const AuthContext = createContext<ContextType>({ session: null, user: null });

export function AuthProvider({ children }: AuthProps) {
	const [session, setSession] = useState<Session | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const location = useLocation();

	const subdomain = window.location.hostname.split(".")[0];

	const { isLoading: subscriptionLoading, error: subscriptionError } = useQuery<any, AxiosError>({
		queryKey: ["subscription", session?.user?.id],
		queryFn: () => fetchSubscription(session?.user.id || ""),
		enabled: !!session?.user?.id,
		retry: 3,
	});

	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const hadAccessQuery = useQuery({
		queryKey: ["hadAccess", session?.user?.id],
		queryFn: () => hadAccess(session?.user.id || ""),
		enabled: !!session?.user?.id,
	});

	const { data: user, isLoading: userLoading, error: userError } = useQuery({
		queryKey: ["user", session?.user?.id],
		queryFn: () => fetchUser(session?.user?.id),
		enabled: !!session?.user?.id,
	});

	useEffect(() => {
		const session = checkCookie(config.cookieName);
		if (session) {
			setSession(session);
		}
		else if (!location.pathname.includes("profile")) {
			window.location.href = config.loginPath;
		}
	}, [location.pathname]);

	useEffect(() => {
		if (userError !== null // there aren't errors on user request
			|| (subdomain === "talent" && session?.user.plan_slug === "m1-o26") // verifies that user cannot change his subdomain
		) {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.remove(config.cookieName, { domain });
			window.location.href = config.loginPath;
		}
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session, user, userError]);

	useEffect(() => {
		if (subscriptionError) {
			window.location.href = subscriptionError.response?.data?.redirect_to;
		}
	}, [subscriptionError]);


	useEffect(() => {
		if (!userLoading && user?.id && config.isProduction) {
			analytics.initialize(user.id, `${user.firstname} ${user.lastname}`);
			analytics.pageview(window.location.pathname + window.location.search);
		}
	}, [user, userLoading, location.pathname]);

	// Make the provider update only when it should
	const memoedValue = useMemo(
		() => ({
			session, user,
		}),
		[session, user],
	);

	if (subscriptionLoading || subscriptionError) return <Loader />;

	/* set AuthContext */
	return (
		<AuthContext.Provider value={memoedValue}>
			{!isLoading && children}
		</AuthContext.Provider>
	);
}


export default function useAuth() {
	return useContext(AuthContext);
}
