
import { CircleProgress } from "react-gradient-progress";

import { Button, Typography } from "design2impact";

import { FaBusinessTime } from "react-icons/fa";

import { Master, Topic } from "types/defs";
import { Nullable } from "types/utils";


interface CardPathProps {
	topic: Topic,
	id: string,
	subinfo: Nullable<string>
	title: string,
	student_infos: Master["student_infos"]
	description: string,
	courses_count: number
	projects_count: number
	job_success_rate: Nullable<number>
	job_success_time: Nullable<number>
	toggleModal: () => void
	startNewPath: (id: string) => void
}

const CardPath = ({
	topic,
	id,
	subinfo,
	title,
	student_infos,
	description,
	courses_count,
	projects_count,
	job_success_rate,
	job_success_time,
	toggleModal,
	startNewPath,
}: CardPathProps) => {
	return (
		<div className="mb-3 rounded-l-2xl border border-grey-200 shadow">
			<div className="rounded-[inherit] border-l-[1.5rem] pl-5 pr-4 shadow" style={{ borderColor: topic?.color }}>
				<div className="mb-4 grid grid-cols-12 gap-6">

					<div className="col-span-12 lg:col-span-10">
						<div className="grid grid-cols-12 gap-2">
							{
								subinfo && (
									<Typography variant="label-3" className="col-span-12 mt-6 w-fit rounded border border-solid border-greyscale-primary px-1.5 py-0.5">
										{subinfo}
									</Typography>
								)
							}
							<div className={`col-span-12 md:mb-0 ${subinfo ? "mt-2" : "mt-6"}`}>
								<div className="grid grid-cols-12 gap-1.5 lg:gap-6">
									<div className="col-span-12 md:col-span-3">
										<Typography variant="h3" className="inline-block shrink-0">{title}</Typography>
									</div>

									<div className="col-span-12 my-3 flex flex-wrap items-start gap-2.5 md:col-span-4 md:my-0">
										<div className="inline-flex w-[120px] shrink-0 cursor-default items-center rounded-[4px] border-2 border-solid border-greyscale-primary px-4 py-2">
											<Typography variant="h3">{courses_count}</Typography>
											&nbsp;
											<Typography variant="label-2">corsi</Typography>
										</div>
										<div className="inline-flex w-[120px] shrink-0 cursor-default items-center rounded-[4px] border-2 border-solid border-greyscale-primary px-4 py-2">
											<Typography variant="h3">{projects_count}</Typography>
											&nbsp;
											<Typography variant="label-2">progetti</Typography>
										</div>
									</div>
									<div className="col-span-12 md:col-span-5">
										{/* eslint-disable-next-line react/no-danger */}
										<p className="text-paragraph-span2-mobile sm:text-paragraph-span2-desktop" dangerouslySetInnerHTML={{ __html: description }} />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={`col-span-12 flex items-center text-left md:flex-col md:text-center lg:col-span-2 lg:px-0 ${subinfo ? "md:mt-6" : "md:mt-4"}`}>
						<Typography variant="paragraph-span3" className="flex-col items-center justify-end gap-2 p-0">
							Completamento:
						</Typography>

						<div className="col-span-6 flex-col items-center justify-end gap-2 p-0 text-paragraph-span3-mobile sm:text-paragraph-span3-desktop md:col-span-12 md:mt-1">
							<CircleProgress
								percentage={student_infos?.progress || 0}
								width={75}
								strokeWidth={7}
								primaryColor={["#007369", "#08F7A1"]}
								secondaryColor="#E6F1F0"
								fontColor="#222"
							/>
						</div>
					</div>

				</div>

				<hr className="text-greyscale-primary/20" />

				<div className="grid grid-cols-12 gap-4">
					<div className="col-span-12 flex items-center md:col-span-6 lg:col-span-4">
						{
							job_success_rate && (
								<div className="mb-3 flex items-center md:mb-0">
									<FaBusinessTime size={32} className="mr-2.5 shrink-0 text-brand-base" />
									<Typography variant="paragraph-span2">
										<strong>
											Il
											{" "}
											{job_success_rate}
											%
										</strong>
										{" "}
										di chi finisce questo Master trova lavoro entro
										{" "}
										{job_success_time}
										{" "}
										mesi
									</Typography>
								</div>
							)
						}
					</div>

					<div className="hidden md:col-span-2 md:block" />
					<div className="col-span-12 mb-3 flex flex-row-reverse gap-3 md:col-span-4 md:mt-4 lg:col-span-6">
						<Button as="button" type="button" onClick={() => startNewPath(id)}>
							{student_infos?.is_current ? "Riprendi" : "Inizia"}
						</Button>
						<Button as="button" variant="secondary" className="normal-case" type="button" onClick={() => toggleModal()}>
							Scopri di pi&ugrave;
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardPath;
