import { useEffect } from "react";
import { Helmet } from "react-helmet";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Button, Typography } from "design2impact";

import config from "config";

const Community = () => {
	const { userSession } = useUserSessionContext();


	useEffect(() => {
		if (userSession.email === "mariuzzistefano@gmail.com" && window.dataLayer) {
			window.dataLayer.push({
				subscription_status: "trialing",
			});
		}
	}, [userSession]);


	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession?.plan_slug)[4].title}</title>
			</Helmet>

			<div className="flex flex-col justify-between bg-ui-secondary text-greyscale-primary">

				<main className="flex min-h-screen items-center py-4">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="col-span-12 mb-5 pt-2">
								<div className="mx-auto max-w-[53.75rem]">
									<img
										className="block h-auto w-full"
										src={`${config.cloudinaryFetchPath}c_fill,w_860/https://talent.start2impact.it/assets/slides/s2i_community-ff0036c677edff8171f1ced179fe74719b91b0c6ee83e231de17011180b17620.jpg`}
										alt="Foto della community start2impact"
										loading="eager"
										width="860"
										height="574"
									/>
								</div>
							</div>

							<div className="col-span-12 text-center">
								<Typography variant="h1">
									Entra a far parte della Community
								</Typography>
								<Typography variant="paragraph-p1" className="mb-4">
									Potrai confrontarti sia con esperti, sia con studenti con i tuoi
									stessi interessi.
								</Typography>
								<Button
									as="external-link"
									href="https://start2impact.typeform.com/to/l8bjJErq#source=pagina_community_piattaforma"
									className="mx-auto mb-4 w-fit"
									rel="noreferrer"
								>
									Entra nel gruppo
								</Button>
							</div>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default Community;
