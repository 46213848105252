/* eslint-disable max-len */

import { Doughnut } from "react-chartjs-2";

import { Button } from "design2impact";
import { date } from "helpers2impact";

import { getProjectDetails } from "../projects";
import { FaFileInvoice } from "react-icons/fa";

import { Nullable } from "types/utils";

import styles from "./style.module.css";

interface ProjectProgressProps {
	isMobile: boolean
	isFinalProject: boolean
	test_points: number
	project_retries: number
	project_resend: boolean
	project_sent_date: Nullable<boolean>
	project_blocked_until: Nullable<string>
	projects_count: number
	projects_completed: number
	projects_points: string | null
	guidePath: string
	doughnutSetting: any
}

const ProjectProgress = ({
	isMobile,
	isFinalProject,
	test_points,
	project_retries,
	project_resend,
	project_sent_date = null,
	project_blocked_until = null,
	projects_count,
	projects_completed,
	projects_points = null,
	guidePath,
	doughnutSetting,
}: ProjectProgressProps) => {
	const checkProjectBlockedUntil = () => {
		return project_blocked_until !== null && date.calculateHoursToNow(project_blocked_until) > 0;
	};

	const projectDetails = getProjectDetails(
		test_points,
		project_blocked_until,
		project_retries,
		project_resend,
		project_sent_date,
		projects_count,
		projects_completed,
		projects_points,
		isFinalProject,
	);

	const projectsData = {
		maintainAspectRatio: true,
		responsive: true,
		labels: [[projectDetails.text]],
		datasets: [
			{
				data: projectDetails.data,
				backgroundColor: (context: any) => projectDetails.backgroundColor(context),
				hoverBorderColor: ["transparent"],
			},
		],
		icon: projectDetails.icon,
		isButtonActive: projectDetails.button,
	};

	// Desktop
	return (
		!isMobile ? (
			projects_count > 0 && (
				<div className={styles.detailCourseContainer}>
					<div className={styles.detailCourseLabelContainer}>
						<div>
							<div className="relative w-14">
								<Doughnut data={projectsData} options={doughnutSetting} width={80} />
								<div>
									<FaFileInvoice
										className={`${styles.chartIcon} ${projectsData.icon[projectsData.icon.length - 1]}`}
									/>
								</div>
							</div>
						</div>
						<div className="ml-4">
							<ul>
								{projectsData.labels.map((label: any, index) => (
									<li key={`doughnut_project_key_${index}`} className={styles.detailCourseLabel}>
										<span dangerouslySetInnerHTML={{ __html: label }} />
									</li>
								))}
							</ul>
						</div>
					</div>
					{
						projects_completed === projects_count ? (
							<Button
								href={`${guidePath}#project_to_show`}
								as="external-link"
								size="md"
							>
								{projects_completed === projects_count
									? "VISUALIZZA FEEDBACK"
									: projects_count > 1
										? "VAI AI PROGETTI"
										: "VAI AL PROGETTO"}
							</Button>
						) : (
							<Button
								disabled={!projectsData.isButtonActive}
								variant={checkProjectBlockedUntil() ? "secondary" : "primary"}
								href={`${guidePath}#project_to_show`}
								as="external-link"
								size="md"
							>
								VAI
								{" "}
								{projects_count > 1 ? "AI PROGETTI" : "AL PROGETTO"}
							</Button>
						)
					}
				</div>
			)
		) : (
			!!projects_count && (
				<div className={styles.carouselMobileItemContainer}>
					<div className={`${styles.carouselMobileItemLabelContainer} ${isMobile && "h-auto"}`}>
						<div className="relative col-span-2 w-14">
							<Doughnut data={projectsData} options={doughnutSetting} width={80} />
							<div>
								<FaFileInvoice
									className={`${styles.chartIcon} ${projectsData.icon[projectsData.icon.length - 1]}`}
								/>
							</div>
						</div>
						<ul className="col-span-10 pl-4">
							{projectsData.labels.map((label: any, index) => (
								<li className={styles.detailCourseLabel} key={`doughnut_project_key_${index}`}>
									<span dangerouslySetInnerHTML={{ __html: label }} />
								</li>
							))}
						</ul>
					</div>

					{
						projects_completed === projects_count ? (
							<Button
								href={`${guidePath}#project_to_show`}
								as="external-link"
								size="md"
							>
								{projects_completed === projects_count
									? "VISUALIZZA FEEDBACK"
									: projects_count > 1
										? "VAI AI PROGETTI"
										: "VAI AL PROGETTO"}
							</Button>
						) : (
							<Button
								disabled={!projectsData.isButtonActive}
								variant={checkProjectBlockedUntil() ? "secondary" : "primary"}
								href={`${guidePath}#project_to_show`}
								as="external-link"
								size="md"
							>
								VAI
								{" "}
								{projects_count > 1 ? "AI PROGETTI" : "AL PROGETTO"}
							</Button>
						)
					}
				</div>
			)
		)
	);
};


export default ProjectProgress;
