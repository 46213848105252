import { ReactNode } from "react";

import { Typography } from "design2impact";

import { ClassNameType } from "types/utils";


interface EditableCardProps {
	title?: string
	button?: ReactNode
	body?: ReactNode
	className?: ClassNameType
}

const EditableCard = ({ title = "", button = false, body = false, className = "" }: EditableCardProps) => {
	return (
		<div className={className}>
			<div className="mb-4 flex items-center justify-between">
				{!!title && (
					<Typography variant="label-1" className="mb-0">
						{title}
					</Typography>
				)}
				<div>{!!button && button}</div>
			</div>
			<div className="inline-flex w-full items-center rounded-2xl bg-brand-primary-light p-4">
				{!!body && body}
			</div>
		</div>
	);
};


export default EditableCard;
