
import { useEffect, useState } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Typography } from "design2impact";

import { BiBell } from "react-icons/bi";
import { FaFileInvoice } from "react-icons/fa";
import { IoList } from "react-icons/io5";

import { setAllNotificationsAsRead, setNotificationAsRead } from "api/mutations";
import { fetchNotifications } from "api/queries";

import { Notification } from "types/defs";


const NotificationBell = () => {
	const notificationsAreRead = false;
	const { userSession } = useUserSessionContext();
	const [unreadedNotifications, setUnreadedNotifications] = useState<Notification[]>([]);

	const { data: notifications, refetch: refetchNotifications } = useQuery({
		queryKey: ["notifications", userSession?.id],
		queryFn: () => fetchNotifications(userSession?.id),
		enabled: !!userSession?.id && userSession.plan_slug !== "m1-o26",
	});

	const { mutate: setAllNotificationsAsReadMutation } = useMutation({
		mutationKey: ["notifications", userSession?.id],
		mutationFn: setAllNotificationsAsRead,
		onSuccess: () => refetchNotifications(),
	});

	const { mutate: setNotificationAsReadMutation } = useMutation({
		mutationKey: ["notifications", userSession?.id],
		mutationFn: setNotificationAsRead,
		onSuccess: () => refetchNotifications(),
	});

	const handleClickNotification = (notification: Notification) => {
		setNotificationAsReadMutation({ userId: userSession.id, notificationId: notification.id });
		if (notification.url) window.location.href = notification.url;
	};

	useEffect(() => {
		if (notifications && notifications.length > 0) {
			setUnreadedNotifications(notifications.filter(notification => notification.read_at === null));
		}
	}, [notifications]);

	return (
		<Popover className="relative ml-2">
			{({ open }) => (
				<>
					<PopoverButton
						className={`relative size-14 rounded-lg border-none text-greyscale-secondary shadow focus-visible:outline-none ${open ? "bg-brand-primary" : "bg-greyscale-base"}`}
					>
						<BiBell style={open ? { color: "#fff" } : {}} className="mx-auto text-[34px]" />
						{!notificationsAreRead && unreadedNotifications.length > 0 && (
							<div className="absolute right-3 top-3 flex h-4 min-w-4 items-center justify-center rounded-full bg-feedback-error px-0.5 text-center">
								<Typography variant="paragraph-span4" className="!font-bold text-greyscale-base">
									{unreadedNotifications.length}
								</Typography>
							</div>
						)}
					</PopoverButton>

					<PopoverPanel className="absolute right-0 z-10 mt-5 w-[350px] rounded-lg bg-greyscale-base shadow ">
						<div className="flex items-center justify-between border-b-2 border-brand-primary-light p-4">
							<Typography variant="label-2">
								Notifiche
								{" "}
								{(!notificationsAreRead && unreadedNotifications.length > 0) && `(${unreadedNotifications.length})`}
							</Typography>
							{!notificationsAreRead && unreadedNotifications.length > 0 && (
								<button
									type="button"
									onClick={() => setAllNotificationsAsReadMutation(userSession.id)}
									className="inline-flex items-center border-none p-0 text-greyscale-primary no-underline"
								>
									<Typography variant="paragraph-span3">
										Segna tutte come gi&agrave; lette
									</Typography>
									{" "}
									<IoList size={16} className="ml-2" />
								</button>
							)}
						</div>
						<div className="max-h-[400px] overflow-y-auto">
							{notifications && notifications.length > 0 ?
								notifications.map((notification, index) => {
									const notificationNotRead =
										notification.read_at === null && !notificationsAreRead;
									return (
										<button
											type="button"
											className={`w-full border-[rgba(0,0,0,.1)] p-4 text-left hover:bg-[#e9ecef] [&:not(:last-child)]:border-b-[0.063rem] ${notificationNotRead && "bg-brand-primary-light"} ${!notification.url && "!cursor-default"}`}
											key={`${notification.body_text + index}`}
											onClick={() => handleClickNotification(notification)}
										>
											<div className="flex items-center">
												<div className="mr-5 shrink-0 text-[20px]">
													<FaFileInvoice
														className={`text-greyscale-secondary/60 ${notificationNotRead && "!text-brand-primary"}`}
													/>
												</div>
												<div className="grow">
													<Typography variant="label-2" className="text-greyscale-primary">
														{notification.header}
													</Typography>
													<Typography
														variant="paragraph-span2"
														style={{
															wordWrap: "break-word",
															overflowWrap: "break-word",
															hyphens: "auto",
															lineBreak: "strict",
															whiteSpace: "normal",
														}}
														className="mt-1 text-greyscale-primary"
													>
														{notification.body_text}
													</Typography>
												</div>
											</div>
										</button>
									);
								}) : <Typography variant="paragraph-span2" className="p-4">Non ci sono notifiche</Typography>}
						</div>
					</PopoverPanel>
				</>
			)}
		</Popover>
	);
};

export default NotificationBell;
