/* eslint-disable no-unused-expressions */
import ReactGA from "react-ga";

import config from "../config";


const queryParams = new URLSearchParams(window.location.search);


const analytics = {
	initialize: (user_id: string, fullname: string) => {
		// eslint-disable-next-line no-console
		queryParams.get("debug") && console.log(`Analytics initialize for user: ${fullname}`);
		return ReactGA.initialize(config.googleAnalyticsTrackerId, {
			debug: !!queryParams.get("debug"),
			titleCase: false,
			gaOptions: {
				userId: user_id,
				name: fullname,
			},
		});
	},
	pageview: (path: string) => {
		// eslint-disable-next-line no-console
		queryParams.get("debug") && console.log(`Analytics pageview path: ${path}`);
		return ReactGA.pageview(path);
	},
};

export default analytics;
