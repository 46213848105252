

import { CardCourseExpandible } from "components/card-course";
import ContainerWithLabel from "./container-with-label";

import { Master } from "types/defs";
import { Nullable } from "types/utils";
import config from "config";

interface GuideCardListProps {
	containers: Nullable<Master["containers"]>
}

const GuideCardList = ({ containers }: GuideCardListProps) => {
	return (
		<div className="!mb-3">
			{containers?.map((container, i) => {
				const doesContainLabel = container.type.id !== "7f2f2cd0-7121-4038-89e8-6b15546c6cd3"; // a scelta tra, progetto finale, opzionale
				const isFinalProject = container.type.id === "1140639d-bbc7-47c7-9477-a2218daf02f2";
				const cardLabel = container.type.label.toLowerCase() === "opzionale" ? "Corso Opzionale" : container.type.label;

				return (
					<ContainerWithLabel
						displayLabel={doesContainLabel || isFinalProject}
						type={cardLabel}
						id={container.id}
						key={`label-container-${i}-id-${container.id}`}
					>
						{container.details.map((pathGuide) => {
							const pic_url = pathGuide.course.image
								? config.cloudinaryFetchPath + pathGuide.course.image.url
								: "https://via.placeholder.com/150";
							return (
								<CardCourseExpandible
									key={`course-item-${pathGuide.course.id}`}
									pic_url={pic_url}
									course={pathGuide.course}
									requirements={pathGuide.requirements}
									guideTypeId={container.type.id}
									isFinalProject={isFinalProject}
								/>
							);
						})}
					</ContainerWithLabel>
				);
			})}
		</div>
	);
};

export default GuideCardList;
