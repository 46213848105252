import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "context/auth-context";
import PortalRenewalAlert from "portals/alerts/renewal-alert";
import PortalBadge from "portals/badges/badge";

import LayoutSidebar from "layouts/sidebar";
import LayoutWithoutSidebar from "layouts/without-sidebar";
import Error404 from "features/404";
import Community from "features/community";
import Courses from "features/courses";
import Masters from "features/masters";
import Ranking from "features/ranking";
import StudentHome from "features/student-home";
import TutorAi from "features/tutor-ai";
import ModalWelcomeVideo from "containers/modal-welcome-video";

import config from "config";

import "react-responsive-carousel/lib/styles/carousel.min.css";


const Talent = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<ModalWelcomeVideo />
				<PortalBadge />
				<PortalRenewalAlert />

				<Routes>
					<Route path={`${config.basepath}`} element={<LayoutSidebar />}>
						<Route path="community" element={<Community />} />
						<Route path="corsi" element={<Courses />} />
						<Route path="classifica" element={<Ranking />} />
						<Route path="master" element={<Masters />} />
						<Route index element={<StudentHome />} />
					</Route>
					<Route path={`${config.basepath}`} element={<LayoutWithoutSidebar />}>
						<Route path="/tutor" element={<TutorAi />} />
						<Route path={config.onboardingPath} element={<Masters />} />
						<Route path="*" element={<Error404 />} />
					</Route>
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	);
};

export default Talent;
