import { useEffect } from "react";
import { useCustomerly } from "react-live-chat-customerly";
import { Outlet } from "react-router-dom";
import useAuth from "context/auth-context";

import { Footer } from "design2impact";

import config from "config";

interface LayoutWithoutSidebarProps {
	hideFooter?: boolean;
}


const LayoutWithoutSidebar = ({ hideFooter = false }: LayoutWithoutSidebarProps) => {
	const { session } = useAuth();
	const { open } = useCustomerly();


	useEffect(() => {
		document.body.classList.remove("body-with-sidebar");
		document.body.classList.add("body-without-sidebar");
		const rootElement = document.getElementById("root");
		if (rootElement) {
			rootElement.style.marginLeft = "0";
		}
	}, []);

	if (!session) return null;

	return (
		<>
			<Outlet context={{ userSession: session.user }} />
			{!hideFooter && <Footer openCustomerlyLiveChat={config.isProduction ? () => open() : undefined} />}
		</>
	);
};

export default LayoutWithoutSidebar;
