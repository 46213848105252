import { useEffect, useRef, useState } from "react";
import { set } from "react-ga";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "@tanstack/react-query";
import useUserSessionContext from "helpers/useUserSessionContext";

import { Loader, TextInput, Typography } from "design2impact";

import { CardCourseStatic } from "components/card-course";
import TopicSelector from "components/topic-selector";

import { fetchCourses } from "api/queries";

import { Course } from "types/defs";
import config from "config";


const showedCourses = 10;
const cloudinaryPath = "https://res.cloudinary.com/start2impact/image/fetch/h_160,w_160/";

const Courses = () => {
	const { userSession } = useUserSessionContext();
	const inputRef = useRef<HTMLInputElement>(null);

	const [courses, setCourses] = useState<Course[]>([]);
	const [selectedTopicId, setSelectedTopicId] = useState<string>("");
	const [showedCoursesCounter, setShowedCoursesCounter] = useState(showedCourses);

	const { data: coursesData, isLoading, error } = useQuery({
		queryKey: ["courses"],
		queryFn: () => fetchCourses(),
	});

	// when user select topic clear the input, selectTopicId active tab then filter courses by selected topicId - if null show all courses 10 at a time
	// then reset the showedCourses counter
	const filterCoursesByTopic = (topicId: string) => {
		if (inputRef.current) {
			inputRef.current.value = "";
		}
		setSelectedTopicId(topicId);
		if (topicId === "suggested") {
			const suggestedCourses = coursesData?.courses.filter((course: any) => course.is_suggested);
			setCourses(suggestedCourses ?? []);
		}
		else {
			const coursesByTopic = topicId ? coursesData?.courses.filter((course: any) => course.topic.id === topicId)
				: coursesData?.courses.slice(0, showedCourses);
			const nonIntroCoursesByTopic = coursesByTopic?.filter((topic: any) => topic.is_intro === false);
			setCourses(nonIntroCoursesByTopic ?? []);
		}
		setShowedCoursesCounter(showedCourses);
	};

	let debounceTimer: any;

	const debouncedChangeCoursesSearch = (ev: any) => {
		setSelectedTopicId("");
		if (debounceTimer) {
			clearTimeout(debounceTimer);
		}
		// if the input is empty (so the user is deleting his search) shows all courses
		if (!ev.target.value) {
			filterCoursesByTopic("");
		}
		else {
			debounceTimer = setTimeout(() => {
				const filteredCourses = coursesData?.courses.filter((course: any) => course.title.toLowerCase().includes(ev.target.value.trim().toLowerCase()));
				setCourses(filteredCourses ?? []);
			}, 350);
		}
	};

	// when user scrolls to the bottom of the page take the array of courses displayed and add "n" courses to display and increase the counter by "n" courses displayed
	const showMoreCourses = () => {
		if (selectedTopicId === "" && inputRef?.current?.value === "" && coursesData) {
			setCourses((prevState) => prevState.concat(
				coursesData?.courses.slice(showedCoursesCounter, showedCoursesCounter + showedCourses),
			));
			setShowedCoursesCounter((prevState) => prevState + showedCourses);
		}
	};

	useEffect(() => {
		// default state show all courses
		if (coursesData) {
			filterCoursesByTopic("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coursesData]);

	if (isLoading || error) return <Loader />;

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[2].title}</title>
			</Helmet>

			<div className="min-h-screen bg-ui-secondary pb-6 pt-10">

				<div className="container mx-auto">
					<div>

						<div className="mb-14 flex items-center justify-between">
							<Typography variant="h1">Corsi</Typography>
						</div>

						{coursesData?.topics && coursesData.topics.length > 0 ?
							<TopicSelector suggestedField className="!justify-start" topics={coursesData?.topics} onSelectTopicFn={filterCoursesByTopic} selectedTopicId={selectedTopicId} /> : null}

						<div className="mt-8">
							<TextInput
								label="Cerca un Corso"
								name="searchCourse"
								ref={inputRef}
								id="searchCourse"
								onChange={debouncedChangeCoursesSearch}
								placeholder="Scrivi il nome del Corso che stai cercando"
							/>
						</div>
					</div>

					<InfiniteScroll
						loader={false}
						className="!overflow-visible pt-8"
						dataLength={courses.length}
						next={showMoreCourses}
						hasMore={coursesData?.courses?.length ? courses.length < coursesData?.courses?.length : false}
					>
						{courses.length > 0 &&
							courses.map(course => {
								return (
									<div key={course.id} className="mb-6">
										<CardCourseStatic
											pic_url={course?.image?.url ? cloudinaryPath + course.image.url : "https://via.placeholder.com/56"}
											course={course}
										/>
									</div>
								);
							})}
					</InfiniteScroll>
				</div>
			</div>
		</>
	);
};


export default Courses;
