import { useState } from "react";
import { Description } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button, Modal, Typography } from "design2impact";

import { FaRegEdit } from "react-icons/fa";

import { updateWorkingSectors } from "api/mutations";

import { FetchStudentResponse } from "types/api";
import { WorkingSector } from "types/defs";

interface WorkingSectorSelectable extends WorkingSector {
	is_selected?: boolean
}

interface ModalWorkingSectorProps {
	sectorsList: WorkingSectorSelectable[]
	userId: string
	modal: boolean
	setModalFn: (val: boolean) => void
}

const WorkingSectorModal = ({ sectorsList, userId, modal, setModalFn }: ModalWorkingSectorProps) => {
	const queryClient = useQueryClient();

	const [newListWorkingSectors, setnewListWorkingSectors] = useState<WorkingSectorSelectable[]>(sectorsList);

	const { mutate: updateWorkingSectorsMutation } = useMutation({
		mutationKey: ["workingSectors", userId],
		mutationFn: updateWorkingSectors,
		onSuccess: (data) => {
			queryClient.setQueryData(["studentData", userId], (oldData: FetchStudentResponse) => ({
				...oldData,
				user: {
					...oldData.user,
					working_sectors: data,
				},
			}));

			setModalFn(!modal);
		},
	});

	const toggleArrayItem = (arr: WorkingSectorSelectable[], item: WorkingSectorSelectable) => {
		const arrCopy = Array.from(arr);
		const itemIdx = arrCopy.findIndex(el => el.id === item.id);
		typeof arrCopy[itemIdx].is_selected === "undefined"
			? arrCopy[itemIdx] = ({ ...arrCopy[itemIdx], is_selected: true })
			: arrCopy[itemIdx].is_selected = !arrCopy[itemIdx].is_selected;
		return arrCopy;
	};

	const refreshWSList = (item: WorkingSectorSelectable) => {
		const newList = toggleArrayItem(newListWorkingSectors, item);
		setnewListWorkingSectors(newList);
	};

	return (
		<>
			<button aria-label="Chiudi il modale dei settori lavorativi" type="button" onClick={() => setModalFn(!modal)}>
				<FaRegEdit className="size-4" />
			</button>
			<Modal
				isVisible={modal}
				toggleModalFn={setModalFn}
				title="Scegli i settori di tuo interesse su cui fare i progetti pratici."
			>
				<div className="mt-2">
					<Description className="text-paragraph-span2-mobile text-greyscale-primary sm:text-paragraph-span2-desktop">
						Tramite Progetti basati su settori di tuo interesse scoprirai come le tue competenze,
						oltre ad essere molto richieste dalle aziende, generano un impatto positivo sulla
						società e sul pianeta. Seleziona uno o più settori.
					</Description>
					<div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2">
						{
							newListWorkingSectors.map(item => {
								return (
									<div
										onClick={() => refreshWSList(item)}
										aria-hidden="true"
										key={`col_sector_${item.id}`}
										className={`flex cursor-pointer items-center rounded-lg border-brand-primary-light p-4 ${item.is_selected === true && "!border-brand-base"} border-2`}
									>
										<img
											height={72}
											width={72}
											className="mr-4"
											src={
												item.filename
													? `https://res.cloudinary.com/start2impact/image/upload/v1667204106/Piattaforma/working_sectors/${item.filename}`
													: "https://via.placeholder.com/72"
											}
											alt={`Icona settore lavorativo ${item.title}`}
										/>

										<div>
											<Typography variant="paragraph-p1" className="w-full">{item.title}</Typography>
											<Typography variant="paragraph-span3" className="mt-1 text-greyscale-secondary">
												{item.description || "Descrizione non presente sul db"}
											</Typography>
										</div>
									</div>
								);
							})
						}
					</div>
				</div>
				<div className="mt-4 flex w-full px-8">
					<Button
						as="button"
						className="mx-auto"
						onClick={() => updateWorkingSectorsMutation({ userId, list: newListWorkingSectors })}
					>
						SALVA MODIFICHE
					</Button>
				</div>
			</Modal>
		</>
	);
};


export default WorkingSectorModal;
